import BasePopoverManager from './BasePopoverManager'
import {
  PROMOTION_COUPON_MIX,
} from './popoverTypes'

/**
 * 迷你车气泡管理器
 */
class MiniPopoverManager extends BasePopoverManager {
  constructor() {
    super({
      priority: [
        PROMOTION_COUPON_MIX
      ],
      globalConditions: {
        isGuideOpen: false,
        isModalOpen: false,
        isFilter: false,
      }
    })
  }
}

const miniPopoverManager = new MiniPopoverManager()
if (typeof window !== 'undefined') window.miniPopoverManager = miniPopoverManager
export default miniPopoverManager
