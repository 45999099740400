<template>
  <div
    v-expose="{
      id: '1-8-2-6',
      data: { mall_code: mallCode, store_code: storeCode }
    }"
    class="get-coupon-entry"
    da-event-click="1-8-2-7"
    :data-mall_code="mallCode"
    :data-store_code="storeCode"
    @click="openGetCouponDrawer"
  >
    {{ language.SHEIN_KEY_PWA_20957 }}
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'GetCoupon'
})
</script>

<script setup>
import { vExpose } from 'public/src/pages/common/analysis/directive'
import { computed, defineProps } from 'vue'
import { useStore } from '@/public/src/pages/cart_v2/hooks/useStore.js'

const props = defineProps({
  mallCode: { // mall编码
    type: [String, Number],
    default: ''
  },
  storeCode: { // 店铺编码
    type: [String, Number],
    default: ''
  },
})

const { state, commit } = useStore()
const language = computed(() => state.language)
const carts = computed(() => state.carts)

// 打开领券弹窗
const openGetCouponDrawer = async() => {
  const products = []
  carts.value?.map(item => {
    if (item.store_code == props.storeCode) {
      products.push({
        skc: item?.product?.goods_sn || ''
      })
    }
  })
  commit('updateState', {
    key: 'mallInfoForGetCoupon',
    value: {
      mall_code: props.mallCode,
      store_code: props.storeCode,
      products
    }
  })
  commit('toggleCartGetCouponDrawer', { value: true })
}
</script>

<style lang="less" scoped>
.get-coupon-entry {
  position: relative;
  color: #2D68A8;
  cursor: pointer;
  margin-left: 16/75rem;
}
</style>
