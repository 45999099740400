<template>
  <div 
    class="item-optional-deals"
    :class="{ 'is-coupon': isCoupon }"
    :style="styleInfo"
  >
    <CornerTag
      v-if="item.rightCornerIcon"
      :img-src="item.rightCornerIcon"
      :text="item.rightCornerTip"
    />
    <WaterMark :text="item.stateText" />
    <div class="wrapper">
      <div class="left">
        <SAdapterText
          class="coupon-face"
          min-size="12"
          :text="item.thresholdTip"
        />
        <p 
          class="ellipsis coupon-threshold"
        >
          {{ item.applyTip }}
        </p>
      </div>
      <div class="right">
        <p 
          class="tips" 
          v-html="item.tip"
        >
        </p>
        <CountDown
          v-if="item.endTimeStamp"
          class="end-in"
          :prefix="item.endTip"
          :timeStamp="item.endTimeStamp"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import CountDown from './CountDown.vue'
import CornerTag from './CornerTag.vue'
import { SAdapterText } from '@shein-aidc/sui-adapter-text/mobile'
import WaterMark from './WaterMark.vue'

export default defineComponent({
  name: 'ItemOptionalDeals',
  components: { 
    CountDown, 
    CornerTag,
    SAdapterText,
    WaterMark,
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    isCoupon() {
      return this.item?.type === 'coupon'
    },
    goods() {
      return this.item?.products || []
    },
    styleInfo() {
      return {
        '--box-bg': '#FFF6F3',
        '--box-border': '#FFE2CF',
      }
    },
  },
  methods: {
  },
})
</script>

<style lang="less" scoped>
@BorderWidth: 1/37.5rem;
@CircleLeft: 84/37.5rem;
@CircleRadius: 4/37.5rem;
@CircleBgColor: #FFF;
.item-optional-deals {
  width: 100%;
  padding: 8/37.5rem 12/37.5rem;
  position: relative;
  border-width: @BorderWidth;
  border-style: solid;
  border-color: var(--box-border);
  border-radius: 4/37.5rem;
  background: var(--box-bg);
  z-index: 1;
  display: flex;
  align-items: center;
  &.is-coupon {
    &::before {
      content: '';
      position: absolute;
      width: @CircleRadius * 2;
      height: @CircleRadius;
      background-color: @CircleBgColor;
      border-radius: 0 0 @CircleRadius @CircleRadius;
      border: @BorderWidth solid var(--box-border);
      border-top: none;
      left: @CircleLeft - @CircleRadius;
      top: -@BorderWidth;
    }
    &::after {
      content: '';
      position: absolute;
      width: @CircleRadius * 2;
      height: @CircleRadius;
      background-color: @CircleBgColor;
      border-radius: @CircleRadius @CircleRadius  0 0;
      border: @BorderWidth solid var(--box-border);
      border-bottom: none;
      left: @CircleLeft - @CircleRadius;
      bottom: -@BorderWidth;
    }
  }
  .wrapper {
    position: relative;
    width: 100%;
    min-height: 44/37.5rem;
    overflow: hidden;
    display: inline-flex;
    align-items: stretch;
  }
  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 72.5/37.5rem;
    margin-right: 8/37.5rem;
    padding-right: 8/37.5rem;
    overflow: hidden;
    border-right: 1px dashed #FDC1A5;
    .coupon-face {
      line-height: 20px;
      font-size: 16px;
      color: #FA6338;
      font-weight: bold;
    }
    .coupon-threshold {
      width: 100%;
      color: rgba(250, 99, 56, 0.70);
      text-align: center;
      font-size: 10px;
      line-height: 12px;
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    min-height: 30/37.5rem;
    .tips {
      line-height: 15px;
      font-size: 12px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .end-in {
      margin-top: 2/37.5rem;
    }
  }
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
