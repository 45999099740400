<template>
  <div 
    class="cart-count-down"
    :style="style"
  >
    <span class="cart-count-down_prefix" v-if="prefix">
      {{ prefix }}
    </span>
    <template v-if="countTimer.timeObj.D !== '00'">
      <span class="cart-count-down_text">
        {{ countTimer.timeObj.D }}
      </span>
    </template>
    <span class="cart-count-down_text">
      {{ countTimer.timeObj.H }}
    </span>
    <span class="cart-count-down_divider">:</span>
    <span class="cart-count-down_text">
      {{ countTimer.timeObj.M }}
    </span>
    <span class="cart-count-down_divider">:</span>
    <span class="cart-count-down_text">
      {{ countTimer.timeObj.S }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { CountDown } from '@shein/common-function'

export default defineComponent({
  props: {
    timeStamp: { // 秒
      type: [Number, String],
      required: false,
      default: 0,
    },
    isDayMode: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: ''
    },
    styleConfig: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      countTimer: new CountDown(),
    }
  },
  computed: {
    style() {
      return {
        '--font-color': '#FA6338',
        '--font-size': '10px',
        '--num-bg-color': 'rgba(250, 99, 56, 0.08)',
        '--num-font-weight': '600',
        '--divider-color': '#FA6338',
        '--divider-font-weight': 'normal',
        '--prefix-margin-right': '0',
        ...this.styleConfig,
      }
    }
  },
  mounted() {
    this.initCountDown()
  },
  methods: {
    // 初始化计时器
    initCountDown() {
      if (!(this.timeStamp)) return
      this.countTimer.start({
        timestamp: Number(this.timeStamp),
        dayMode: this.isDayMode,
        endFunc: () => {},
      })
    },
  },
})
</script>

<style lang="less" scoped>
.cart-count-down {
  line-height: 14px;
  display: inline-block;
  font-weight: normal;
  font-size: var(--font-size);
  color: var(--font-color);
  text-align: left;
  /* rtl:begin:ignore */
  direction: ltr;
  .cart-count-down_prefix {
    margin-right: var(--prefix-margin-right);
  }
  .cart-count-down_text {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 14/37.5rem;
    height: 14/37.5rem;
    padding: 0 1/37.5rem;
    border-radius: 2px;
    background-color: var(--num-bg-color);
    font-weight: var(--num-font-weight);
  }
  .cart-count-down_divider {
    margin: 0 2/37.5rem;
    color: var(--divider-color);
    font-weight: var(--divider-font-weight);
  }
}
</style>
