import { createApp } from 'vue'

let instance
function mountComponent() {
  return new Promise(resolve => {
    import(/* webpackChunkName: "checked_tip" */'./CheckedTip.vue').then(module => {
      const component = module.default
      const appDom = document.createElement('div')
      const app = createApp(component)
      instance = app.mount(appDom)
      document.body.appendChild(instance.$el)
      resolve()
    })
  })
}
export default async function showCheckedTip({ btnText, content }, events) {
  if (!instance) {
    await mountComponent()
  }
  instance.show({ btnText, content }, events)
}
