<template>
  <div class="promo-zone">
    <h3 class="title ellipsis">
      {{ info.title }}
    </h3>
    <div class="container">
      <!-- a.已满足券和活动组 -->
      <template v-if="bestChoiceGroup">
        <PromoStickyHead
          class="best-deals-head"
          :bg-color="'linear-gradient(180deg,#ffecec -100%,#f3f2f3)'"
          :radius-color="'#FFECEC'"
          :title="bestChoiceGroup.title"
          :num="bestChoiceGroup.titleTopRightCornerNum"
        >
          <template #topRight>
            <span
              v-if="bestChoiceGroup.rightTips"
              v-tap="{
                id: '1-8-1-55',
              }"
              :style="{
                color: bestChoiceGroup.rightTips.textColor,
              }"
              class="top-right"
              @click="$emit('clickDetail')"
            >
              <span class="ellipsis">{{ bestChoiceGroup.rightTips.text }}</span>
              <Icon 
                name="sui_icon_more_down_12px_2" 
                size="14px" 
              />
            </span>
          </template>
        </PromoStickyHead>
        <div class="list best-choice-groups">
          <div 
            v-if="bestChoiceGroup.bestExtraInfoText"
            class="list-item all-best-tips"
          >
            <img 
              :src="bestChoiceGroup.bestExtraUrl"
              width="14"
              height="14"
            />
            <span class="ellipsis">
              {{ bestChoiceGroup.bestExtraInfoText }}
            </span>
          </div>
          <ItemBestChoice
            v-for="(item, index) in bestChoiceGroup.subItems"
            :key="index"
            v-tap="{
              id: '1-8-1-54',
              data: {
                best_deals: getSaDataCode(item)
              }
            }"
            class="list-item"
            :item="item"
          />
        </div>
      </template>
      <!-- b.未满足券和活动组 -->
      <template v-if="moreDealsGroup">
        <PromoStickyHead
          :bg-color="'#FFF'"
          :radius-color="'#FFECEC'"
          :title="moreDealsGroup.title"
          :num="moreDealsGroup.titleTopRightCornerNum"
        />
        <div class="list">
          <ItemMoreDeals
            v-for="(item, index) in moreDealsGroup.subItems"
            :key="index"
            v-tap="{
              id: '1-8-1-54',
              data: {
                unlock_deals: getSaDataCode(item)
              }
            }"
            class="list-item"
            :item="item"
            :expandPid="expandPid"
            @handleExpand="handleExpand"
          />
        </div>
      </template>
      <!-- c.已满足的非最优券组 -->
      <template v-if="otherPromoGroup">
        <PromoStickyHead
          :bg-color="'#FFF'"
          :radius-color="moreDealsGroup ? '#FFF' : '#FFECEC'"
          :title="otherPromoGroup.title"
          :num="otherPromoGroup.titleTopRightCornerNum"
        >
          <template #topRight>
            <span
              v-if="otherPromoGroup.rightTips"
              v-expose="{ id: 'expose_cartpromos_viewmore.page_cart' }"
              :style="{
                color: otherPromoGroup.rightTips.textColor,
              }"
              class="top-right"
              @click="viewMore"
            >
              <span class="ellipsis">{{ otherPromoGroup.rightTips.text }}</span>
              <Icon 
                is-responsive-name
                :name="expandOtherPromoList ? 'sui_icon_more_up_12px_2' : 'sui_icon_more_down_12px_2'" 
                size="14px" 
              />
            </span>
          </template>
          <template #bottom>
            <p class="optional-sub-tips ellipsis">
              {{ otherPromoGroup.subTitle }}
            </p>
          </template>
        </PromoStickyHead>
        <div
          ref="otherPromoRef"
          class="list" 
        >
          <template v-if="expandOtherPromoList">
            <ItemOptionalDeals
              v-for="(item, index) in otherPromoGroup.subItems"
              :key="index"
              class="list-item"
              :item="item"
            />
          </template>
        </div>
      </template>
      <div
        v-if="info.bottomTip"
        class="no-more-tips"
      >
        <span class="txt ellipsis">{{ info.bottomTip }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { expose, tap } from 'public/src/pages/common/analysis/directive'
import { defineComponent, nextTick } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import PromoStickyHead from './components/PromoStickyHead.vue'
import ItemBestChoice from './components/ItemBestChoice.vue'
import ItemMoreDeals from './components/ItemMoreDeals.vue'
import ItemOptionalDeals from './components/ItemOptionalDeals.vue'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
// import { debounce } from '@shein/common-function'

export default defineComponent({
  name: 'PromoZone',
  directives: {
    expose,
    tap,
  },
  components: {
    Icon,
    PromoStickyHead,
    ItemBestChoice, // 已凑满
    ItemMoreDeals, // 正在凑单
    ItemOptionalDeals, // 满足且有其他凑单选项
  },
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      expandOtherPromoList: false,

      expandPid: '',
    }
  },
  computed: {
    bestChoiceGroup() {
      return this.info?.items?.find(v => v.groupId === 'GetBestChoise')
    },
    moreDealsGroup() {
      return this.info?.items?.find(v => v.groupId === 'UnlockMoreDeals')
    },
    otherPromoGroup() {
      return this.info?.items?.find(v => v.groupId === 'OtherPromotion')
    },
  },
  watch: {
    moreDealsGroup: {
      handler() {
        if (typeof window === 'undefined') return
        this.checkPromotionIsSatisfied()
      },
      deep: true,
    }
  },
  mounted() {
    this.sendSa('1-8-1-52')
    this.getDefaultExpandPid()
  },
  beforeUnmount() {
    this.sendSa('1-8-1-53')
  },
  methods: {
    getDefaultExpandPid() {
      const item = this.moreDealsGroup?.subItems?.find((v) => !!v.products?.length)
      if (!item) return
      this.handleExpand(item.promotionId || item.couponCode)
    },
    handleExpand(pid) {
      if (this.expandPid === pid) {
        this.expandPid = ''
        return
      }
      this.expandPid = pid
    },
    sendSa(daId) {
      const extraData = this.getSaData()
      daEventCenter.triggerNotice({ 
        daId: daId,
        extraData: extraData,
      })
    },
    getSaDataCode(data) {
      const { type, promotionId, couponCode, typeId } = data || {}
      let pid = ''
      if (type === 'freeShipping') {
        pid = 'freeship'
      }
      if (type === 'creditReward') {
        pid = 'clubcredit'
      } else {
        pid = promotionId || couponCode
      }

      return `${pid}_${typeId || '-'}`
    },
    getSaData() {
      const promo_types = []
      let best_deals, unlock_deals, optionnal_deals
      if (this.bestChoiceGroup) {
        promo_types.push('bestdeals')
        best_deals = this.bestChoiceGroup.subItems?.map?.(v => this.getSaDataCode(v))?.join(',')
      }
      if (this.moreDealsGroup) {
        unlock_deals = this.moreDealsGroup.subItems?.map?.(v => this.getSaDataCode(v))?.join(',')
        promo_types.push('unlockdeals')
      }
      if (this.otherPromoGroup) {
        optionnal_deals = this.otherPromoGroup.subItems?.map?.(v => this.getSaDataCode(v))?.join(',')
        promo_types.push('optionnaldeals')
      }
      return {
        promo_type: promo_types.join(','),
        details: this.bestChoiceGroup?.rightTips ? '1' : '0',
        best_deals,
        unlock_deals,
        optionnal_deals,
      }
    },
    // onScroll: debounce({
    //   func(el) {
    //     if (this.showNoMoreTips) return
    //     const scrollHeight = el.target?.scrollHeight
    //     const clientHeight = el.target?.clientHeight
    //     const scrollTop = el.target?.scrollTop
    //     // 判断是否滚动到底部
    //     if (scrollTop + clientHeight >= scrollHeight - 1) {
    //       this.showNoMoreTips = true
    //     }
    //   },
    //   wait: 300,
    // }),
    viewMore() {
      daEventCenter.triggerNotice({
        id: 'click_cartpromos_viewmore.page_cart',
        data: {
          click_type: this.expandOtherPromoList ? 'fold' : 'unfold',
        }
      })

      this.expandOtherPromoList = !this.expandOtherPromoList
      if (this.expandOtherPromoList) {
        nextTick(() => {
          this.$refs.otherPromoRef?.scrollIntoView({ behavior: 'smooth' })
        })
      }
    },
    checkPromotionIsSatisfied() {
      const pid = window.sessionStorage.getItem('checked_goods_promotion_id')
      if (!pid) return
      const pidList = this.moreDealsGroup?.subItems?.map(v => v.promotionId || v.couponCode)
      if (!pidList.includes(pid)) {
        SToast(this.language.SHEIN_KEY_PWA_34545)
        this.getDefaultExpandPid()
      }
      window.sessionStorage.removeItem('checked_goods_promotion_id')
    },
  },
})
</script>

<style lang="less" scoped>
.promo-zone {
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, #FFECEC 0%, #F3F2F3 74/37.5rem, #F3F2F3 100%);
  display: flex;
  flex-direction: column;
  .title {
    text-align: center;
    padding: 12/37.5rem 16/37.5rem;
    line-height: 20px;
  }
}
.container {
  flex: 1;
  position: relative;
  overflow-y: auto;
  overscroll-behavior: none;
  border-top-left-radius: 12/37.5rem;
  border-top-right-radius: 12/37.5rem;
  display: flex;
  flex-direction: column;
  .best-deals-head {
    :deep(.promo-sticky-head) { /* stylelint-disable-line selector-max-specificity */
      padding-top: 0 !important; /* stylelint-disable-line declaration-no-important */
    }
  }
  .optional-sub-tips {
    height: 12/37.5rem;
    color: #959595;
    font-size: 10px;
    padding-left: 2/37.5rem;
  }
  .list {
    position: relative;
    padding: 0 12/37.5rem;
    background-color: #fff;
    &.best-choice-groups {
      background-color: transparent;
      padding-bottom: 12/37.5rem;
      & + .no-more-tips { /* stylelint-disable-line selector-max-specificity */
        padding: 18/37.5rem 12/37.5rem 22/37.5rem;
        background-color: transparent;
      }
    }
    & + .no-more-tips { /* stylelint-disable-line selector-max-specificity */
      padding: 30/37.5rem 12/37.5rem 22/37.5rem;
      background-color: #fff;
    }
    .all-best-tips {
      display: flex;
      align-items: center;
      color: #FA6338;
      padding: 6/37.5rem 8/37.5rem;
      border: 0.5px solid #FFAF8B;
      border-radius: 4px;
      background: linear-gradient(0deg, #FFECE6 0%, #FFECE6 100%), #FFF;
      span {
        padding-left: 3/37.5rem;
      }
    }
    &:last-of-type {
      flex: 1;
      padding-bottom: 12/37.5rem;
    }
    .list-item {
      &:not(:last-of-type) {  /* stylelint-disable-line selector-max-specificity */
        margin-bottom: 8/37.5rem;
      }
    }
  }
  .top-right {
    background-color: #FFF;
    display: flex;
    height: 20/37.5rem;
    padding: 0 6/37.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 12/37.5rem;
    margin-left: 16/37.5rem;
    max-width: 107/37.5rem;
    color: #000;
    span {
      margin-right: 2/37.5rem;
    }
  }
  .no-more-tips {
    position: relative;
    color: @sui_color_gray_light1;
    display: flex;
    align-items: center;
    justify-content: center;
    .txt {
      max-width: 85%;
    }
    &::before {
      content: ' ';
      width: 12/37.5rem;
      height: 1/37.5rem;
      background-color: #959595;
      margin-right: 8/37.5rem;
    }
    &::after {
      content: ' ';
      width: 12/37.5rem;
      height: 1/37.5rem;
      background-color: #959595;
      margin-left: 8/37.5rem;
    }
  }
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
