import { computed, ref, watch } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { useItemDisplayView } from './useItemDisplayView.js'
import { CART_ITEM_GUIDE_ADD_ON } from 'public/src/pages/cart_v2/utils/popoverManager/popoverTypes.js'
import { usePopoverManager } from 'public/src/pages/cart_v2/utils/popoverManager/usePopoverManager.js'

const StorageKeys = {
  TRIGGER_COUNT: 'cart_v2_select_guide_add_on_tip_trigger_count',
  LAST_TRIGGER_TIMESTAMP: 'cart_v2_select_guide_add_on_tip_last_trigger_timestamp',
  TRIGGER_ITEM_IDS: 'cart_v2_select_guide_add_on_tip_trigger_item_ids',
}

// 检查当前商品行是否已触发过凑单提示
const checkItemTriggered = (id) => {
  if (typeof window === 'undefined') return true
  const triggerItemIds = JSON.parse(window.sessionStorage.getItem(StorageKeys['TRIGGER_ITEM_IDS'])) || []
  return triggerItemIds?.includes(id)
}

export function useCartItemGuideAddOn() {
  const { commit, state } = useStore()

  // 业务逻辑相关配置
  const guideAddOnItemConfig = computed(() => state.cartConfigs?.guideAddOnItem || { addOnItemStyle: 'off' })

  // cartItemGuideAddOnState
  const currentChangeItemId = computed(() => state.cartItemGuideAddOnState.currentChangeItemId)

  // 当前操作的商品行信息
  const cartItemMap = computed(() => state.cartItemMap)
  const currentChangeItem = computed(() => cartItemMap.value?.[currentChangeItemId.value] || {})
  const aggregateProductBusiness = computed(() => currentChangeItem.value?.aggregateProductBusiness || {})
  const biData = computed(() => aggregateProductBusiness.value?.productRowBiData?.guideAddOnItemBiData || {})
  const guideInfo = computed(() => aggregateProductBusiness.value?.guideAddOnItemData || {})

  // 中间层有返回提示信息
  const bodyTip = computed(() => guideInfo.value?.bodyTip || '')
  // 触发次数是否超出
  const triggerCountExceeded = computed(() => {
    if (typeof window === 'undefined') return true
    const currentTriggerCount = window.sessionStorage.getItem(StorageKeys['TRIGGER_COUNT'])
    return currentTriggerCount >= +guideAddOnItemConfig.value.triggerMaxTimes
  })
  // 是否满足时间间隔
  const isTimeIntervalSatisfied = computed(() => {
    if (typeof window === 'undefined') return false
    const lastTriggerTimestamp = window.sessionStorage.getItem(StorageKeys['LAST_TRIGGER_TIMESTAMP'])
    const currentTimeStamp = Date.now()
    return currentTimeStamp - +lastTriggerTimestamp >= +guideAddOnItemConfig.value?.triggerInterval * 1000
  })
  // 当前商品行是否已触发过
  const currentItemTriggered = computed(() => checkItemTriggered(currentChangeItemId.value))

  // 是否满足基础展示条件
  const baseShowConditions = computed(() => {
    return Boolean(bodyTip.value
      && !currentItemTriggered.value
      && !triggerCountExceeded.value
      && isTimeIntervalSatisfied.value)
  })
  if (guideAddOnItemConfig.value?.addOnItemStyle == 'planA') {
    const { itemInVisible, updateItemInVisible } = useItemDisplayView()
    const { isActivePopover, deactivatePopover, activatePopover, registerPopover } = usePopoverManager({
      type: CART_ITEM_GUIDE_ADD_ON,
    })
    registerPopover()

    const isShow = computed(() => {
      return Boolean(baseShowConditions.value && isActivePopover.value && itemInVisible.value)
    })
    watch(() => currentChangeItemId.value, (val) => {
      if (!!val && baseShowConditions.value) {
        updateItemInVisible(currentChangeItemId.value)
        activatePopover()
      }
    })
    watch(() => isActivePopover.value, (val) => {
      if (val) {
        updateItemInVisible(currentChangeItemId.value)
      }
    })
    watch(() => itemInVisible.value, (val) => {
      if (!val) {
        deactivatePopover()
      }
    })

    watch(() => isShow.value, (val) => {
      if (val) {
        commit('updateCartItemGuideAddOnState', {
          currentGuideAddOnCartId: currentChangeItemId.value,
          showPopover: true,
          showFloor: false
        })
        daEventCenterExpose(biData.value)
        setTimeoutId.value = setTimeout(() => {
          commit('updateCartItemGuideAddOnState', {
            showPopover: false,
          })
          updateCacheInfo(currentChangeItemId.value)
          clearTimeoutFn()
          deactivatePopover()
        }, 5000)
      } else {
        deactivatePopover()
      }
    })
  }

  if (guideAddOnItemConfig.value?.addOnItemStyle == 'planB') {
    // 是否有负向信息
    const hasNegativeInfo = computed(() => {
      const negativeInfo = aggregateProductBusiness.value?.negativeInfo || {}
      if (
        (negativeInfo.showNotPoints === '1' && negativeInfo.notPointsText) ||
        (negativeInfo.showNotCoupon === '1' && negativeInfo.notCouponText) ||
        (negativeInfo.showNotReturn === '1' && negativeInfo.notReturnText)
      ) {
        return true
      }
      return false
    })
    // 是否定制商品
    const isCustomizationProduct = computed(() => {
      return currentChangeItem.value?.isInvalid != 1 &&
        !!(aggregateProductBusiness.value?.customizationPopInfo?.customContent && aggregateProductBusiness.value?.customizationPopInfo?.effects && aggregateProductBusiness.value?.customizationPopInfo?.images && aggregateProductBusiness.value?.customizationPopInfo?.texts)
    })
    // 是否低库存商品
    const isAlmostProduct = computed(() => !!aggregateProductBusiness.value?.cartAlmostPopupEntryCartItem || !!aggregateProductBusiness.value?.cartSameLureEntryInfoDto)

    const isFinallyShow = computed(() => {
      return baseShowConditions.value
        && !isCustomizationProduct.value
        && !isAlmostProduct.value
        && !hasNegativeInfo.value
    })

    watch(() => currentChangeItemId.value, () => {
      if (isFinallyShow.value) {
        commit('updateCartItemGuideAddOnState', {
          currentGuideAddOnCartId: currentChangeItemId.value,
          showPopover: false,
          showFloor: true 
        })
        daEventCenterExpose(biData.value)
        updateCacheInfo(currentChangeItemId.value)
      }
    })
  }

  const updateCacheInfo = (id) => {
    updateTriggerItemIds(id)
    updateTriggerCount()
    updateTriggerTimestamp()
  }

  // 更新已触发id，存入session，本次会话内不再触发
  const updateTriggerItemIds = (id) => {
    if (typeof window === 'undefined') return true
    const triggerItemIds = JSON.parse(window.sessionStorage.getItem(StorageKeys['TRIGGER_ITEM_IDS'])) || []
    triggerItemIds.push(id)
    window.sessionStorage.setItem(StorageKeys['TRIGGER_ITEM_IDS'], JSON.stringify(triggerItemIds))
  }
  // 更新触发次数
  const updateTriggerCount = () => {
    if (typeof window === 'undefined') return
    const currentTriggerCount = window.sessionStorage.getItem(StorageKeys['TRIGGER_COUNT'])
    window.sessionStorage.setItem(StorageKeys['TRIGGER_COUNT'], +currentTriggerCount + 1)
  }
  // 更新触发时间戳
  const updateTriggerTimestamp = () => {
    if (typeof window === 'undefined') return
    window.sessionStorage.setItem(StorageKeys['LAST_TRIGGER_TIMESTAMP'], Date.now())
  }

  // 凑单曝光
  const daEventCenterExpose = (biData) => {
    const { styleType, goodsId, skuCode, promotionTypeId, promotionId } = biData
    daEventCenter.triggerNotice({
      id: 'expose_cart_addonitem.page_cart',
      extraData: {
        style_type: styleType,
        goods_id: goodsId,
        sku_code: skuCode,
        promotion_type_id: promotionTypeId,
        promotion_id: promotionId,
      },
    })
  }

  let setTimeoutId = ref(null)
  const clearTimeoutFn = () => {
    clearTimeout(setTimeoutId.value)
    setTimeoutId.value = null
  }
  return {
    clearCartItemGuideOnTimeoutFn: clearTimeoutFn
  }
}
