/**
 * @param {object} info
 * @param {string} info.type
 * @param {string} info.pop_entrance_from
 * @param {string} info.title
 * @param {string} info.titleIcon
 * @param {string} info.backgroundImg
 * @param {string} info.description
 * @param {string[]} info.cartIds
 */
export const formatToggleIncentiveProductListDialogInfo = (info) => {
  return {
    type: info.type,
    pop_entrance_from: info.pop_entrance_from,
    titleIcon: info.titleIcon,
    title: info.title,
    backgroundImg: info.backgroundImg,
    backgroundColor: '#fff',
    cartLureList: [
      {
        icon: '',
        subTitle: info.description,
        cartIds: info.cartIds,
        backgroundImage: 'linear-gradient(0deg, #FFF 50%, #FFF4CD 100%)',
      }
    ]
  }
}
