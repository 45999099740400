import { daEventCenter } from 'public/src/services/eventCenter/index'


export const click_store_name = (data, callback) => {
  const defaultData = {
    store_code: data.isSheinStore == '1' ? '-' : (data.store_code || '-'),
    store_tp: data.isSheinStore == '1' ? '-' : data.store_type == 1 ? 1 : 2,
    preferred_seller: data.isSheinStore == '1' ? '-' : data.preferred_seller_store == 1 ? 1 : 0,
    store_label: data.isSheinStore == '1' ? '-' : data.store_trend_logo ? 'trends' : '-',
  }
  daEventCenter.triggerNotice({ daId: '1-8-7-45', extraData: callback?.(defaultData) ?? defaultData })
}


export const expose_store_name = (data, callback) => {
  const defaultData = {
    store_code: data.isSheinStore == '1' ? '-' : (data.store_code || '-'),
    store_tp: data.isSheinStore == '1' ? '-' : data.store_type == 1 ? 1 : 2,
    preferred_seller: data.isSheinStore == '1' ? '-' : data.preferred_seller_store == 1 ? 1 : 0,
    store_label: data.isSheinStore == '1' ? '-' : data.store_trend_logo ? 'trends' : '-',
  }
  daEventCenter.triggerNotice({ daId: '1-8-7-46', extraData: callback?.(defaultData) ?? defaultData })
}
