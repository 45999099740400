import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'
import { computed } from 'vue'

export function useGuideCheckoutStateChange() {
  const { commit, state } = useStore()
  const cartItemGuideCheckoutState = computed(() => state.cartItemGuideCheckoutState)

  // 更新当前操作的商品行id
  const handleActiveItemChange = (cartItem) => {
    commit('updateCartItemGuideCheckoutState', {
      currentActiveItemId: cartItem?.id,
    })
  }
  
  // 更新是否达到展示气泡时机
  const handleIsShowPopoverTimeChange = (val) => {
    const isShowPopoverTime = cartItemGuideCheckoutState.value?.currentActiveItemId ? val : false
    commit('updateCartItemGuideCheckoutState', {
      isShowPopoverTime,
    })
    
  }

  // 展示/关闭气泡
  const handlePopoverShowChange = (val) => {
    if (val) {
      commit('updateCartItemGuideCheckoutState', {
        showPopover: true,
        activePopoverCartId: cartItemGuideCheckoutState.value?.currentActiveItemId,
      })
    } else {
      if (cartItemGuideCheckoutState.value?.popoverTimer) {
        clearTimeout(cartItemGuideCheckoutState.value?.popoverTimer)
      }
      commit('updateCartItemGuideCheckoutState', {
        showPopover: false,
        currentActiveItemId: '',
        activePopoverCartId: '',
        isShowPopoverTime: false,
        popoverTimer: null
      })
    }
  }

  return {
    handleActiveItemChange,
    handleIsShowPopoverTimeChange,
    handlePopoverShowChange,
  }
}
