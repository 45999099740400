import { daEventCenter } from 'public/src/services/eventCenter/index'

export const click_custom_content = (data, callback) => {
  const defaultData = {
    images: 0,
    preview: 0,
    texts: 0,
  }
  daEventCenter.triggerNotice({ daId: '1-8-7-63', extraData: callback?.(defaultData) ?? defaultData })
}
