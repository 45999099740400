import { computed } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'
import { daEventCenter } from 'public/src/services/eventCenterV2'

export function useHandleDiscountDetails() {
  const { state, commit } = useStore()

  const showDiscountDetails = computed(() => state.allDiscountDetailDrawerParams.show)
  const isShowDiscountDetailsEntry = computed(() => state.cartInfo.showPromotionDetailPopUp == '1')
  const handleDiscountDetails = (val) => {
    if (showDiscountDetails.value == val) {
      return
    }
    if(!isShowDiscountDetailsEntry.value) return
    commit('changeAllDiscountDetailDrawerParams', {
      show: val,
    })
    const data = {
      is_open: Number(val),
      scenes: 'cart',
    }
    if (val) {
      const item = state.cartInfo?.promotionDetailPopUp?.items?.find?.(item => item.type == 1)
      data.headline_type = (item?.lureInfo?.lureList || []).map(v => `${v?.biData?.name}\`${v?.biData?.num}`).join(',')
    }
    daEventCenter.triggerNotice({
      id: '1-8-2-2',
      data,
    })
  }

  return {
    handleDiscountDetails,
  }
}