import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'

export function useItemCheckedChange() {
  const { commit } = useStore()
  const handleCheckedChange = (CartItem) => {
    if (CartItem?.is_checked == 1) {
      commit('updateCartItemGuideAddOnState', {
        currentChangeItemId: CartItem?.id
      })
    }
  }
  return {
    handleCheckedChange
  }
}
