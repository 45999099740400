<template>
  <div
    class="preferred-seller-icon"
  >
    <img :src="`${publicCdn}/pwa_dist/images/cart/sui_icon_preferred_seller-9f0612ec30.png`" />
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'PreferredSellerStoreIcon',
})
</script>
<script setup>
import { computed } from 'vue'

const { PUBLIC_CDN } = gbCommonInfo
const props = defineProps({
  locals: {
    type: Object,
    default: () => ({})
  }
})
const publicCdn = computed(() => props.locals?.PUBLIC_CDN || PUBLIC_CDN || '')
</script>

<style lang="less" scoped>
.preferred-seller-icon {
  display: inline-flex;
  vertical-align: top;
  width: 32/75rem;
  height: 32/75rem;
  margin-right: 6/75rem;
  img{
    width: 100%;
  }
}
</style>
