import checkoutIncentive from 'public/src/pages/cart_v2/components/cartCheckoutIncentive/checkoutIncentive.js'
import popoverManager from './PopoverManager.js'
import debug from './useDebug'

export function useUpdateFilterState () {
  const setFilterState = () => {
    checkoutIncentive.pause()
    popoverManager.setGlobalCondition('isFilter', true)
    debug && console.warn('气泡全局状态更新筛选状态')
  }
  const resetFilterState = () => {
    popoverManager.setGlobalCondition('isFilter', false)
    checkoutIncentive.restart()
    popoverManager.scheduleTasks()
    debug && console.warn('气泡全局状态重置筛选状态，重新调用异步队列')
  }
  return {
    setFilterState,
    resetFilterState,
  }
}
