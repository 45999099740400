import { daEventCenter } from 'public/src/services/eventCenter/index'

/**
 * 点击优惠详情
 * result_order： 获取当前商品的位置
 */
export const click_discount_details = (data, callback) => {
  const defaultData = {
    goods_id: data.product?.goods_id || '-',
    sku_code: data.product?.sku_code || '-',
    cart_id: data.id,
    result_order: '',
    countdown_promotion_id: data?.aggregateProductBusiness?.estimatedPricePopUp?.biData?.countdown_promotion_id ?? '-',
    countdown_label: data?.aggregateProductBusiness?.productRowBiData?.countdownLabel
  }
  daEventCenter.triggerNotice({ daId: '1-8-3-63', extraData: callback?.(defaultData) ?? defaultData })
}
