<template>
  <div class="add-item-list_b">
    <div
      v-show="!loading"
      class="list-wrapper"
    >
      <BRecommendCartItem
        v-for="item in list"
        :key="item.goods_id"
        class="list-item"
        :data="item"
        :constant="constant"
        :language="language" 
        :use="use"
        :config="config"
        @expose="onExpose(item)"
      />
    </div>
    <s-skeleton 
      v-show="loading"
      animated 
      :count="6" 
    >
      <template #template>
        <div style="display: flex; margin-right: 0.64rem;">
          <s-skeleton-item 
            variant="image" 
            style="width: 1.547rem; height: 1.547rem; margin-right: 0.16rem;" 
          />
          <div style="width: 4.32rem;">
            <s-skeleton-item 
              variant="text" 
              style="width: 100%; height: 0.373rem;"
            />
            <div style="margin-top: -0.0533rem;">
              <s-skeleton-item 
                variant="text"
                style="width: 100%; height: 0.373rem;"
              />
            </div>
            <div
              style="display: flex; align-items: center; justify-content: space-between; width: 100%;"
            >
              <s-skeleton-item 
                variant="text"
                style="width: 1.28rem; height: 0.533rem;"
              />
              <s-skeleton-item 
                variant="text"
                style="width: 0.96rem; height: 0.64rem; border-radius: 0.64rem; margin-top: -0.0533rem;" 
              />
            </div>
          </div>
        </div>
      </template>
    </s-skeleton>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { SSkeleton } from '@shein-aidc/sui-skeleton/mobile'
import { BRecommendCartItem, getConstant } from '@shein-aidc/bs-cart-item-mobile'
import { getPrice, getPriorityLabel } from './utils.js'

const { SSkeletonItem } = SSkeleton
const constant = ref(getConstant())

const emit = defineEmits(['itemExposed', 'itemClicked', 'showQuickCart'])

const props = defineProps({
  loading: {
    type: Boolean,
    default: true,
  },
  isSuggested: {
    type: Boolean,
    default: true,
  },
  list: {
    type: Array,
    default: () => ([])
  },
  language: {
    type: Object,
    default: () => ({})
  }
})

const use = ref([
  'GoodsImg',
  'GoodsTitle',
  'GoodsTitleDiscount',
  'LowStockLabel',
  'GoodsSalesLabel',
  'RankListTag',
  'QuickShipTag',
  'GoodsPrice',
  'GoodsStarRating',
  'AddCart',
])

const config = ref({
  GoodsImg: {
    props({ data }) {
      return {
        imgUrl: data.value.goods_img,
        isClick: false,
      }
    },
    events: {
      onReport: ({ data }) => {
        exposeItemClick(data.value)
      }
    }
  },
  GoodsTitle: {
    props({ data }) {
      return {
        title: data.value.goods_name,
        isClick: false,
      }
    },
    events: {
      onReport: ({ data }) => {
        exposeItemClick(data.value)
      }
    }
  },
  GoodsTitleDiscount: {
    props({ data }) {
      const { unitDiscount } = getPrice(data.value)
      if (props.isSuggested || unitDiscount <= 0) {
        return { isShow: false }
      }
      return {
        isShow: true,
        unitDiscount: `-${unitDiscount}%`,
      }
    },
  },
  LowStockLabel: {
    props({ data }) {
      const { name, props } = getPriorityLabel(data.value)
      if (name === 'LowStockLabel') {
        return {
          isShow: true,
          ...props,
        }
      }
      return { isShow: false }
    },
  },
  GoodsSalesLabel: {
    props({ data }) {
      const salesLabel = data.value.productMaterial?.salesLabel
      if (salesLabel) {
        return {
          isShow: true,
          text: salesLabel.labelLang,
          textColor: salesLabel.fontColor,
        }
      }
      return { isShow: false }
    },
  },
  QuickShipTag: {
    props({ data }) {
      const { name, props } = getPriorityLabel(data.value)
      if (name === 'QuickShipTag') {
        return {
          isShow: true,
          ...props,
        }
      }
      return { isShow: false }
    },
  },
  RankListTag: {
    props({ data }) {
      const { name, props } = getPriorityLabel(data.value)
      if (name === 'RankListTag') {
        return {
          isShow: true,
          ...props,
        }
      }
      return { isShow: false }
    },
  },
  GoodsStarRating: {
    props({ data }) {
      const { name, props } = getPriorityLabel(data.value)
      if (name === 'GoodsStarRating') {
        return {
          isShow: true,
          ...props,
        }
      }
      return { isShow: false }
    },
  },
  GoodsPrice: {
    props({ data }) {
      const { salePrice, salePriceShowStyle, unitDiscount } = getPrice(data.value)
      const salePriceColor = (props.isSuggested || unitDiscount <= 0) ? '#000' : '#FA6338'
      return {
        salePrice,
        salePriceShowStyle,
        salePriceColor,
        isAfterWrap: false,
      }
    },
  },
  AddCart: {
    events: {
      onClick: ({ data }) => {
        emit('showQuickCart', data.value)
      },
    },
  }
})

// methods
const onExpose = (item) => {
  emit('itemExposed', item)
}
const exposeItemClick = (item) => {
  emit('itemClicked', item)
}


</script>
<style lang="less" scoped>
.add-item-list_b {
  .list-wrapper, ::v-deep(.sui-skeleton__wrap) {
    height: 128/37.5rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .list-item {
    width: 226/37.5rem;
    margin-right: 24/37.5rem;
  }
}
</style>
