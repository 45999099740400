<template>
  <div
    ref="marquee"
    :style="style"
    class="text-marquee"
  >
    <div
      ref="text"
      :class="['text-marquee__text', isAnimation && 'text-marquee__text_animation']"
    >
      <slot>{{ content }}</slot>
    </div>
  </div>
</template>

<script>
/**
 * @description 文字跑马灯效果
 * @example <TextMarquee>内容</TextMarquee>
 */
import { defineComponent, nextTick } from 'vue'
export default defineComponent({
  name: 'TextMarquee',

  props: {
    // 跑马灯宽度
    width: {
      type: String,
      default: '68px',
    },
    // 跑马灯速度
    speed: {
      type: Number,
      default: 30,
    },
    // 跑马灯内容
    content: {
      type: String,
      default: '',
    },
    // 内容改变自动重置
    autoReset: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      isAnimation: false,
      duration: 0,
    }
  },

  computed: {
    style() {
      return {
        '--width': `${this.width}`,
        '--duration': `${this.duration}s`,
      }
    },
  },

  mounted() {
    if (this.autoReset) {
      if (this.$slots.default) {
        this.$watch('$slots.default', this.init, {
          immediate: true,
        })
      } else if (this.content) {
        this.$watch('content', this.init, {
          immediate: true,
        })
      }
    } else {
      this.init()
    }
  },

  methods: {
    init() {
      nextTick(() => {
        this.textWidth = this.$el.scrollWidth
        this.isAnimation = this.textWidth > this.$el.clientWidth
        this.duration = (this.textWidth / this.speed).toFixed(2)
      })
    },
  }
})
</script>

<style lang="less" scoped>
.text-marquee {
  overflow: hidden;
  max-width: var(--width, 100%);
  &__text {
    white-space: nowrap;
    width: fit-content;
    &_animation {
      animation-name: marquee;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-duration: var(--duration, 3s);
    }
  }
}
@keyframes marquee {
  0% {
    transform: translateX(0) /* rtl: translateX(-100) */;
  }
  100% {
    transform: translateX(-100%) /* rtl: translateX(0) */;
  }
}
</style>
