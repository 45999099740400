<template>
  <div 
    class="progress-bar"
    :style="style"
  >
    <div
      ref="progressBar"
      class="progress-bar-bg"
      :class="{
        'is-animation': animation,
      }"
    >
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import { gsap } from 'gsap'
const { GB_cssRight } = gbCommonInfo

const DEFAULT_BOTTOM_BG = 'rgba(166, 62, 0, 0.15)'
const DEFAULT_BAR_BG = 'linear-gradient(to right, #FF9900, #FA6A19 38%)'
const DEFAULT_HEIGHT = 6

export default defineComponent({
  components: {},
  props: {
    thresholds: { 
      type: Array,
      default: () => ([])
    },
    animation: { // 是否无限滚动动画
      type: Boolean,
      default: false,
    },
    styleConfig: { // 外界样式配置
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      GB_cssRight,
      isInit: false,
    }
  },
  computed: {
    circleSize() {
      const { progressHeight } = this.styleConfig
      return (progressHeight || DEFAULT_HEIGHT) - 2
    },
    style() {
      const { bottomBg, barBg, progressHeight } = this.styleConfig
      const h = (progressHeight || DEFAULT_HEIGHT) / 37.5 + 'rem'
      return {
        '--bottom-bg': bottomBg || DEFAULT_BOTTOM_BG,
        '--bar-bg': barBg || DEFAULT_BAR_BG,
        '--progress-height': h,
      }
    },
  },
  watch: {
    thresholds: {
      handler() {
        nextTick(() => {
          this.startProgressAnimation()
        })
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    startProgressAnimation() {
      if (!this.thresholds?.length) return
      const ele = this.$refs.progressBar

      const { val } = this.formatProgress(this.thresholds)
      let tl = gsap.timeline({ duration: .1 })
      // 首次进入
      if (!this.isInit) {
        tl.to(ele, { 
          width: `${val}%`,
          onComplete: () => {
            tl.kill()
          },
        })
        this.isInit = true
        return
      }

      // 已经初始化，分四种情况
      tl.to(ele, { 
        width: `${val}%`,
        onComplete: () => {
          tl.kill()
        },
      })
    },
    formatProgress(arr) {
      if (!arr?.length) return null
      return {
        val: arr[0]?.progressPercent * 100
      }
    },
  },
})
</script>

<style lang="less" scoped>
@BorderRadius: 12/75rem;

.progress-bar {
  position: relative;
  width: 100%;
  height: var(--progress-height);
  background: var(--bottom-bg);
  border-radius: @BorderRadius;
  overflow: hidden;

  .progress-bar-bg {
    position: relative;
    box-sizing: border-box;
    height: var(--progress-height);
    border-radius: @BorderRadius;
    background-size: 450/37.5rem;
    background-image: var(--bar-bg);
    max-width: 100%;
    width: 0%;
    &::after {
      position: absolute;
      width: 100%;
      height: 100%;
      content: '';
      background-image: repeating-linear-gradient(115deg, transparent 0%, transparent 1%, rgba(255, 255, 255, 0.3) 1%,rgba(255, 255, 255, 0.3) 2%);
      background-size: 450/37.5rem;
    }
    &.is-animation::after {
      animation: slider-ltr 16s linear infinite /* rtl: slider-rtl 16s linear infinite */;
      transition: width .3s linear;
    }
  }

  .ico {
    position: absolute;
    top: 1/37.5rem;
  }
}

@keyframes slider-ltr {
  0% { background-position-x: 100%; }
}
@keyframes slider-rtl {
  0% { background-position-x: -100%; }
}
</style>
