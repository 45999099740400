import { computed } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'

export function useItemGuideAddOnState(item) {
  const { state } = useStore()
  const currentGuideAddOnCartId = computed(() => state.cartItemGuideAddOnState.currentGuideAddOnCartId)
  const isCurrentGuideAddOnCartItem = computed(() => item.id === currentGuideAddOnCartId.value)
  const hasCartSameLureEntryInfo = computed(() => !!item.aggregateProductBusiness?.cartSameLureEntryInfoDto)
  // 当前商品存在相同利益点入口时，不展示凑单引导楼层
  const showFloor = computed(() => isCurrentGuideAddOnCartItem.value && state.cartItemGuideAddOnState.showFloor && !hasCartSameLureEntryInfo.value)
  const showPopover = computed(() => isCurrentGuideAddOnCartItem.value && state.cartItemGuideAddOnState.showPopover)
  return {
    showCartItemGuideAddOnPopover: showPopover,
    showCartItemGuideAddOnFloor: showFloor,
  }
}
