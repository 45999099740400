/**
 * 购物车低库存气泡
 */
export const CART_ITEM_LOW_STOCK = 'CART_ITEM_LOW_STOCK'

/**
 * 底部促转
 */
export const BOTTOM_PROMOTION_TRANSFORMATION = 'BOTTOM_PROMOTION_TRANSFORMATION'

/**
 * 商品行正向交互引导结算气泡
 */
export const CART_ITEM_GUIDE_CHECKOUT = 'CART_ITEM_GUIDE_CHECKOUT'

/**
 * 新品推荐
 */
export const NEW_GOODS_RECOMMENDED = 'NEW_GOODS_RECOMMENDED'

/**
 * 新品推荐C
 */
export const NEW_GOODS_RECOMMENDED_C = 'NEW_GOODS_RECOMMENDED_C'

/**
 * 融合气泡
 */
export const PROMOTION_COUPON_MIX = 'PROMOTION_COUPON_MIX'

/**
 * 商品行引导凑单
 */
export const CART_ITEM_GUIDE_ADD_ON = 'CART_ITEM_GUIDE_ADD_ON'

/**
 * 顶部券筛选凑单气泡
 */
export const TOP_COUPON_FILTER_ADD_ON = 'TOP_COUPON_FILTER_ADD_ON'
/**
 * 筛选 tab 新人引导
 */
export const FILTER_TAB = 'FILTER_TAB'
/**
 * 价格变高气泡提示
 */
export const PRICE_CHANGE = 'PRICE_CHANGE'

/**
 * 券筛选后底部checkout气泡
 */
export const BOTTOM_COUPON_FILTER_CHECKOUT = 'BOTTOM_COUPON_FILTER_CHECKOUT'

/**
 * 长时间低效用户引导至底部推荐位
 */
export const LOW_EFFICIENCY_GUIDE_RECOMMENDED = 'LOW_EFFICIENCY_GUIDE_RECOMMENDED'

