<template>
  <span class="corner-tag">
    <img 
      height="10" 
      :src="imgSrc"
    />
    <span class="tag-tip">{{ text }}</span>
  </span>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CornerTag'
})
</script>
<script setup>
defineProps({
  imgSrc: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  }
})
</script>
<style lang="less" scoped>
.corner-tag {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-flex;
  align-items: center;
  height: 10/37.5rem;
  border-radius: 4/37.5rem 0rem;
  padding: 0 4/37.5rem;
  background-color: rgba(246, 69, 30, 0.12);
  .tag-tip {
    color: #A63E00;
    font-size: 9px;
    line-height: 9/37.5rem;
    padding-left: 2/37.5rem;
  }
}
</style>
