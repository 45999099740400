import { expose_insured_goods } from 'public/src/pages/cart_v2/analysis/item/insurePriceTag.js'
import { useTimeEndRefresh } from 'public/src/pages/cart_v2/hooks/useTimeEndRefresh.js'

export function useInsurePriceTagEvent() {
  const { refreshHanlder } = useTimeEndRefresh()

  const onExpose = ({ itemOpts: { data, event }}) => {
    if (event?.type == 'insurePrice') {
      expose_insured_goods(data.value)
    }
  }

  const onTimeEnd = () => {
    refreshHanlder()
  }

  return {
    onExpose,
    onTimeEnd,
  }
}
