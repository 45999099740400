import { ref } from 'vue'
import { throttle } from '@shein/common-function'
import { useEventListener } from './useEventListener.js'

export const useScrollDirection = (callback) => {
  let lastScrollTop = 0
  const scrollDirection = ref() // up | down

  const calcScrollDirection = throttle({
    func() {
      const st = window.scrollY || document.documentElement.scrollTop
      let sDirection
      if (st > lastScrollTop) {
        sDirection = 'up'
      } else {
        sDirection = 'down'
      }
      lastScrollTop = st
      scrollDirection.value = sDirection
      callback?.(scrollDirection.value)
    },
    wait: 300,
    options: {
      trailing: false,
    },
  })

  useEventListener('scroll', calcScrollDirection)

  return {
    scrollDirection,
  }
}
