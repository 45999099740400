import { ref, computed, watch } from 'vue'
import { usePopoverManager } from 'public/src/pages/cart_v2/utils/popoverManager/usePopoverManager.js'
import { FILTER_TAB } from 'public/src/pages/cart_v2/utils/popoverManager/popoverTypes.js'
import { useStore } from '@/public/src/pages/cart_v2/hooks/useStore.js'

const popoverLangKey = {
  'additems': 'SHEIN_KEY_PWA_37437',
  'wishlist': 'SHEIN_KEY_PWA_36660',
}
 
export const useFilterPopoverGuide = ({ scrollToTarget, refLabel }) => {
  const { state } = useStore()
  const language = computed(() => state.language)
  const cartLeave = computed(() => state.cartLeave)
  const { isActivePopover, activatePopover, deactivatePopover, registerPopover } = usePopoverManager({ type: FILTER_TAB })
  registerPopover()
  const isShow = ref(false)
  let tagId = ''
  const isRealShow = computed(() => isShow.value)
  const showFilterPopoverGuide = computed(() => isRealShow.value && isActivePopover.value)

  const setShowPopover = (value) => {
    refLabel.value?.forEach(target => {
      target.setShowPopover?.({ 
        value, 
        key: tagId, 
        msg: language.value[popoverLangKey[tagId]]
      })
    })
  }
  
  let showTimer
  let hideTimer
  const clearHideTimer = () => {
    clearTimeout(hideTimer)
    hideTimer = null
  }
  const setHideTimer = () => {
    clearHideTimer()
    hideTimer = setTimeout(() => {
      isShow.value = false
    }, 3000)
  }
  const clearShowTimer = () => {
    clearTimeout(showTimer)
    showTimer = null
  }
  const setShowTimer = () => {
    clearShowTimer()
    showTimer = setTimeout(() => {
      if (cartLeave.value) return
      setShowPopover(true)
    }, 800)
  }

  watch(isRealShow, val => {
    if (val) {
      activatePopover()
    } else {
      deactivatePopover()
    }
  })

  watch(showFilterPopoverGuide, val => {
    if (typeof window === 'undefined') return
    if (val) {
      setPopoverCache(tagId)
      scrollToTarget(tagId)
      setShowTimer()
      setHideTimer()
    } else {
      clearShowTimer()
      setShowPopover(false)
      scrollToTarget()
      clearHideTimer()
    }
  }, { immediate: true })

  const getPopoverCache = () => {
    const arrStr = localStorage.getItem('CartFilterTagPopoverCache') || ''
    return arrStr ? arrStr.split(',') : []
  }
  const setPopoverCache = (tagId) => {
    if (!tagId) return
    const caches = getPopoverCache()
    caches.push(tagId)
    localStorage.setItem('CartFilterTagPopoverCache', caches.join(','))
  }

  const getNeedShowGuideTag = (tags) => {
    const cahceTags = getPopoverCache()
    const filterTags = tags.filter(tag => !cahceTags.includes(tag.filterTagId)) // 选出未缓存的筛选气泡
    // 优先级 additems > wishlist
    const  tag = filterTags.find(tag => tag.filterTagId == 'additems') || filterTags.find(tag => tag.filterTagId == 'wishlist')
    return tag?.filterTagId
  }

  const setShowFilterPopoverGuide = ({ tags, show }) => {
    if (show) {
      tagId = getNeedShowGuideTag(tags)
      if (!tagId) return
    }
    isShow.value = show
  }

  return {
    setShowFilterPopoverGuide,
    showFilterPopoverGuide,
  }
}
