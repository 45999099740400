import { computed } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'

export function useCartItemGuideCheckoutState(item) {
  const { state } = useStore()

  const showPopover = computed(() => state.cartItemGuideCheckoutState.showPopover)
  const isCurrentActiveItem = computed(() => item.id === state.cartItemGuideCheckoutState.activePopoverCartId)
  const showCartItemGuideCheckoutPopover = computed(() => showPopover.value && isCurrentActiveItem.value)

  return {
    showCartItemGuideCheckoutPopover,
  }
}
