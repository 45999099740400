import { useStore } from 'public/src/pages/cart_v2/hooks/useStore'
import { click_discount_details } from 'public/src/pages/cart_v2/analysis/item/discountTag.js'

/**
 * DiscountLimitTag 原子组件操作
 */
export function useDiscountLimitTagEvent() {
  const { commit, dispatch } = useStore()

  /**
   * 点击 
   */
  const onClick = ({ itemOpts: { data }, analysis = {} }) => {
    const apb = data.value.aggregateProductBusiness || {}
    if (!apb.estimatedPricePopUp) return
    commit('changeDiscountDetailDrawerParams', {
      show: true,
      data: apb.estimatedPricePopUp,
      saData: {
        scene: 'discount',
        id: data.value.id,
        goods_id: data.value.product.goods_id,
      },
    })
    click_discount_details(data.value, analysis.click_discount_details)
  }

  /**
   * 倒计时结束
   * 只在主车页面使用，防止多次请求
   */
  const onCountDownEnd = () => {
    dispatch('fetchCartIndex')
  }

  return {
    onClick,
    onCountDownEnd,
  }
}
