<template>
  <div
    class="promo-popover j-promo-popover"
    @click="handleClick"
  >
    <div class="content">
      <template v-if="notPick">
        <div
          v-if="showImgs.length"
          class="goods"
        >
          <img
            v-for="(item, i) in showImgs"
            :key="i"
            :src="cutImg(item.goodsImg)"
            class="goods-img"
            :style="{
              'z-index': 5 - i,
              [locals.GB_cssRight ? 'margin-right' : 'margin-left']: `-${getLeftMargin(i)/37.5}rem`,
              'width': `${getWidth(i)/37.5}rem`,
              'height': `${getWidth(i)/37.5}rem`,
            }"
          />
          <span 
            v-if="goodsCount" 
            class="goods-count"
          >
            {{ goodsCount }}
          </span>
        </div>
        <span
          class="tips ellipsis"
          v-html="bubbleItem.tip"
        >
        </span>
        <span class="pick-btn ellipsis">
          {{ bubbleItem.buttonTip }}
        </span>
      </template>
      <template v-else>
        <img
          class="left-ico"
          :src="bubbleItem.icon"
        />
        <span
          class="tips"
          v-html="bubbleItem.tip"
        >
        </span>
      </template>
      <Icon
        class="close-ico"
        name="sui_icon_close_16px"
        color="#C1C1C1"
        size="14px"
        @click.stop="handleClose" 
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { useCutImg } from 'public/src/pages/cart_v2/hooks/useCutImg.js'

const getRate = (i) => (10 - 1.6 * i) / 10

export default defineComponent({
  name: 'PromoPopover',
  components: {
    Icon,
  },
  setup() {
    const { cutImg } = useCutImg()
    return {
      cutImg
    }
  },
  props: {
    bubbleItem: {
      type: Object,
      default: () => ({})
    },
    locals: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    notPick() {
      return this.bubbleItem?.type === 'noPickedAdditionalProduct'
    },
    showImgs() {
      return this.bubbleItem?.products?.slice(0, 3) || []
    },
    goodsCount() {
      const length = this.bubbleItem?.productNum || 0
      if (length >= 2 && length <= 99) return length
      if (length >= 100) return '99+'
      return ''
    },
  },
  watch: {
    'bubbleItem': {
      handler(val) {
        if (typeof window === 'undefined') return
        const { bubbleInfo } = val || {}
        daEventCenter.triggerNotice({ 
          daId: '1-8-1-50',
          extraData: {
            bubble_info: bubbleInfo,
          }
        })
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    handleClose() {
      this.sendSa('close')
      this.$emit('close')
    },
    getWidth(i) {
      return 32 * getRate(i)
    },
    getLeftMargin(i) {
      if (i == 0) return 0
      const w = this.getWidth(i)
      return w - 8 * getRate(i)
    },
    handleClick() {
      this.sendSa('body')
      this.$emit('popoverClick', {
        notPick: this.notPick,
        promotionId: this.bubbleItem?.promotionId
      })
    },
    sendSa(type) {
      const { bubbleInfo } = this.bubbleItem || {}
      daEventCenter.triggerNotice({ 
        daId: '1-8-1-51',
        extraData: {
          bubble_info: bubbleInfo,
          button_type: type,
        }
      })
    }
  },
})
</script>

<style lang="less" scoped>
@imgRadius: 6/37.5rem;
.promo-popover {
  position: absolute;
  left: 50%;
  width: 86%;
  transform: translateX(-50%);
  bottom: calc(100% + 10px);
  
  .content {
    display: flex;
    margin: 0 auto;
    width: fit-content;
    padding: 6/37.5rem 12/37.5rem;
    border-radius: 2px;
    background: rgba(0,0,0,0.8);
    color: #fff;
    align-items: center;
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      margin-left: -2/37.5rem;
      border-top: 4/37.5rem solid transparent;
      border-bottom: 4/37.5rem solid rgba(0, 0, 0, 0.8);
      border-right: 4/37.5rem solid rgba(0, 0, 0, 0.8);
      border-left: 4/37.5rem solid transparent;
      transform: rotate(45deg);
      bottom: -3/37.5rem;
    }
  }
  .left-ico {
    height: 16/37.5rem;
  }
  .close-ico {
    position: relative;
    margin-left: 8/37.5rem;
    &::after {
      content: '';
      position: absolute;
      height: 40/37.5rem;
      width: 36/37.5rem;
      right: -0.32rem;
      top: -13/37.5rem;
    }
  }
  .tips {
    font-size: 12px;
    padding-left: 4/37.5rem;
    flex: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .pick-btn {
    color: #fff;
    background: #FA6338;
    width: 56/37.5rem;
    line-height: 16/37.5rem;
    border-radius: 28/37.5rem;
    padding: 4/37.5rem;
    margin-left: 4/37.5rem;
    font-weight: 590;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .goods {
    display: flex;
    align-items: center;
    min-width: 32/37.5rem;
    position: relative;
    .goods-img {
      width: 32/37.5rem;
      height: 32/37.5rem;
      border-radius: @imgRadius;
      border: 0.5px solid #FFF;
      box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.16);
      float: left;
      position: relative;
    }
    .goods-count {
      position: absolute;
      bottom: 0;
      left: 32/37.5rem;
      height: 12/37.5rem;
      line-height: 12/37.5rem;
      font-size: 10px;
      transform: translateX(-100%);
      z-index: 6;
      background-color: rgba(0, 0, 0, 0.7);
      padding: 0 3/37.5rem;
      border-top-left-radius: @imgRadius;
      border-bottom-right-radius: @imgRadius;
    }
  }
}
</style>
