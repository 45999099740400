import { useDiscountLimitTagEvent } from './useDiscountLimitTag.js'
import { useDiscountNormalTagEvent } from './useDiscountNormalTag.js'
import { useEstimatedPriceEvent } from './useEstimatedPrice.js'
import { useGoodsQtyEvent } from'./useGoodsQty.js'
import { useGoodsSaleAttrEvent } from './useGoodsSaleAttr.js'
import { usePromotionTagEvent } from './usePromotionTag.js'
import { useInsurePriceTagEvent } from './useInsurePriceTag.js'
import { useBehaviorLabelEvent } from './useBehaviorLabel.js'
import { useRankLiskTagEvent } from './useRankLiskTag.js'
import { useGoodsCustomContentEvent } from './useGoodsCustomContent.js'
import { useFindSimilarTextEvent } from './useFindSimilarText.js'
import { useSameSellersLabelEvent } from './useSameSellersLabel.js'
import { useGoodsStoreEvent } from './useGoodsStore.js'
import { useLowStockLabelEvent } from './useLowStockLabel.js'

export function useItemEvents() {
  const discountLimitTagEvent = useDiscountLimitTagEvent()
  const discountNormalTagEvent = useDiscountNormalTagEvent()
  const estimatedPriceEvent = useEstimatedPriceEvent()
  const goodsQtyEvent = useGoodsQtyEvent()
  const goodsSaleAttrEvent = useGoodsSaleAttrEvent()
  const promotionTagEvent = usePromotionTagEvent()
  const insurePriceTagEvent = useInsurePriceTagEvent()
  const behaviorLabelEvent = useBehaviorLabelEvent()
  const rankLiskTagEvent = useRankLiskTagEvent()
  const goodsCustomContentEvent = useGoodsCustomContentEvent()
  const findSimilarTextEvent = useFindSimilarTextEvent()
  const sameSellersLabelEvent = useSameSellersLabelEvent()
  const goodsStoreEvent = useGoodsStoreEvent()
  const lowStockLabelEvent = useLowStockLabelEvent()

  return {
    discountLimitTagEvent,
    discountNormalTagEvent,
    estimatedPriceEvent,
    goodsQtyEvent,
    goodsSaleAttrEvent,
    promotionTagEvent,
    insurePriceTagEvent,
    behaviorLabelEvent,
    rankLiskTagEvent,
    goodsCustomContentEvent,
    findSimilarTextEvent,
    sameSellersLabelEvent,
    goodsStoreEvent,
    lowStockLabelEvent,
  }
}
