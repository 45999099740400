<template>
  <div
    class="cart__fullgifts-list se-fullgifts-list"
    :class="{ 'se-fullgifts-list_small': type === 1 }"
  >
    <ul class="fullgift-list">
      <li
        v-for="(item,index) in showGiftList"
        :key="'giftList'+index+item.goods_relation_id"
        class="fullgift-item se-item"
        :data-goods_id="item.goods_id"
        :data-index="index"
        :data-position-index="index+1"
        :data-spu="item.goods_relation_id"
        :data-sku="item.goods_sn"
        :data-cat_id="item.cat_id"
        :data-us-price="item.sale_price && item.sale_price.usdAmount"
        :data-us-origin-price="item.retail_price && item.retail_price.usdAmount"
        :data-promotion_state="disabledTip ? 0 : 2"
        :data-promotion_typeid="4"
        :data-is_satisfied="disabledTip ? 0 : 1"
        :data-mall_code="`mall_${item.mall_code}`"
        :data-mall_tag_code="mallTagInfo(item.mall_code)"
        @click="handleOpenQuickAdd(item,index)"
      >
        <div class="product-img se-product-img">
          <img :src="cutImg( item.goods_img )" />
        </div>
        <span class="add-bag-block">+{{ language.SHEIN_KEY_PWA_15830 }}</span>
      </li>
      <li
        v-if="isShowViewAllBtn"
        v-expose="analysisData()"
        class="fullgift-item view-all"
        @click="handleViewAll"
      >
        <Icon
          name="sui_icon_viewmore_right_40px"
          size="40px" 
          :is-rotate="GB_cssRight"
        />
        <span class="view-all__text">{{ language.SHEIN_KEY_PWA_25063 }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { expose } from 'public/src/pages/common/analysis/directive'
import { Icon } from '@shein-aidc/icon-vue3'
import { defineComponent, nextTick, computed } from 'vue'
import { getImageRatio } from 'public/src/pages/goods_detail_v2/utils/common.js'
import { scrollToCartItem } from 'public/src/pages/cart_v2/utils/index.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { transformImg } from '@shein/common-function'
import { getQuickAddCartInstance } from 'public/src/pages/components/product/quick_add_v3/common'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'
import { useCutImg } from 'public/src/pages/cart_v2/hooks/useCutImg.js'

const { GB_cssRight } = gbCommonInfo

export default defineComponent({
  name: 'FullGiftsListV2',
  directives: {
    expose,
  },
  setup() {
    const { state, commit, dispatch } = useStore()
    const language = computed(() => state.language)
    const siteMallList = computed(() => state.siteMallList)
    const changeAppendageDrawerStatus = (payload) => commit('changeAppendageDrawerStatus', payload)
    const fetchCartIndex = () => dispatch('fetchCartIndex')
    const { cutImg } = useCutImg(state.locals)

    return {
      siteMallList,
      language,
      changeAppendageDrawerStatus,
      fetchCartIndex,
      cutImg
    }
  },
  components: {
    Icon,
  },
  props: {
    giftList: {
      type: Array,
      default(){
        return []
      }
    },
    disabledTip: {
      type: String,
      default: ''
    },
    promotionId: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: Number,
      default: 0
    },
    promotionType: {
      type: [String, Number],
      default: ''
    },
    isTop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      GB_cssRight
    }
  },
  computed: {
    // 展示出来的赠品列表（最多展示10个）
    showGiftList() {
      return this.giftList.slice(0, 10)
    },
    // 是否展示查看全部赠品按钮（超过10个时展示）
    isShowViewAllBtn() {
      return this.giftList.length > 10
    }
  },
  mounted() {
    daEventCenter.triggerNotice({
      daId: '1-8-3-10',
      extraData: {
        promotion_id: this.promotionId
      }
    })
  },
  methods: {
    mallTagInfo(mallCode) {
      if (!this.siteMallList?.length) return ''
      const tags = this.siteMallList.find(item => item.mall_code == mallCode)?.mall_tags || ''
      return `${tags}_${mallCode}`
    },
    async handleOpenQuickAdd (item, index) {
      daEventCenter.triggerNotice({
        daId: '1-8-3-9',
        extraData: {
          promotion_id: this.promotionId
        }
      })
      const addItems = document.querySelectorAll(`.cart__fullgifts-list .fullgift-item`)
      const target = addItems.length && addItems[index] || null
      if (this.disabledTip) {
        return SToast({ htmlString: this.disabledTip })
      }
      const { PricePlugin } = await import('@shein-aidc/bs-quick-add-cart-standard-mobile')
      const imgRatio = await getImageRatio(item.goods_img)
      getQuickAddCartInstance().open(
        {
          goods_id: item.goods_id,
          mallCode: item.mall_code || ''
        },
        {
          plugins: [PricePlugin(item.sale_price.amountWithSymbol)],
          excludePlugins: ['syncCartInfo'],
          analysisConfig: {
            index,
            sourceTarget: target,
            code: 'cartFullGifts',
            from: 'FullGifts',
            promotion_id: this.promotionId,
            promotion_type: 4,
            promotion_state: this.disabledTip ? 0 : 2
          },
          extendedParam: {
            imgRatio
          },
          featureConfig: {
            needMainSalesAttr: false,
            needLockMall: true,
            needClickToDetail: false,
          },
          callbacks: {
            onAddCartComplete: (data) => {
              if (data?.isSuccess) {
                this.onRefreshCartData({ data: { code: '0' } })
              }
            }
          }
        }
      )
    },
    async onRefreshCartData ({ data }) {
      if (data?.code == '0') {
        await this.fetchCartIndex()

        nextTick(() => {
          scrollToCartItem({ id: data.cart?.id, smooth: true })
        })
      }
    },
    // 查看全部赠品
    handleViewAll() {
      let show_position = this.promotionType == 4 ? (this.isTop ? 1 : 2) : '-'
      daEventCenter.triggerNotice({
        daId: '1-8-5-26',
        extraData: {
          promotion_id: this.promotionId,
          promotion_type: this.promotionType,
          show_position,
        }
      })
      this.changeAppendageDrawerStatus({ promotionId: this.promotionId })
    },
    analysisData() {
      let show_position = this.promotionType == 4 ? (this.isTop ? 1 : 2) : '-'
      return {
        id: '1-8-5-25',
        once: false,
        data: {
          promotion_id: this.promotionId,
          promotion_type: this.promotionType,
          show_position,
        }
      }
    },
    transformImg,
  }
})
</script>

<style lang="less" scoped>
.cart__fullgifts-list{
  background: #fff;
  padding: 20/75rem 24/75rem 0;
  .fullgift-list{
    white-space: nowrap;
    overflow-y: hidden;
    line-height: 1;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .fullgift-item{
    display: inline-block;
    margin-right: 16/75rem;
    width: 134/75rem;
    .product-img{
      width: 134/75rem;
      height: 178/75rem;
      position: relative;
      overflow: hidden;
    }
    img{
      width: 100%;
    }
    .special-price{
      color: @sui_color_welfare_dark;
      font-size: 12px;
      font-weight: bold;
      margin-top: 0.16rem;
      display: inline-block;
    }
    .se-product-img{
      width: 136/75rem;
      height: 136/75rem;
    }
    .add-bag-block{
      font-size: 12px;
      display: block;
      text-align: center;
      margin-top: 8/75rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      background: #fff;
      color: @sui_color_gray_dark1;
      padding: 11/75rem 0 9/75rem;
    }
    &.se-item{
      width: 136/75rem;
    }
    &.view-all {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      vertical-align: top;
      width: 48px;
      height: 188/75rem;
    }
    .view-all__text {
        font-size: 10px;
      font-weight: 700;
      text-align: center;
      white-space: normal;
    }
  }
  .add-bag-content{
    bottom: 4/75rem;
    width: 48/75rem;
    height: 48/75rem;
    border-radius: 24/75rem;
    display: inline-block;
    position: absolute;
    right: 4/75rem;
    background: #fff;
    opacity: .8;
    line-height: 48/75rem;
    text-align: center;
  }
  &.se-fullgifts-list{
    background: @sui_color_promo_bg;
    padding: 0 24/75rem 20/75rem;
    &_small {
      padding: 24/75rem 0 0;
    }
  }
}


</style>
