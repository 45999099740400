import { computed, watch } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'

export const useFilterCheckTagDaEvent = () => {
  let clickDaEventData = null

  const { state } = useStore()
  const selectFilterLabel = computed(() => state.filterState?.selectFilterLabel)

  watch(selectFilterLabel, (val) => {
    if (clickDaEventData) {
      if (clickDaEventData.label_id !== val.labelId) {
        clickDaEventData = null
        return
      }
      clickDaEventData.slt_num = val.count
      daEventCenter.triggerNotice({
        daId: '1-8-3-25',
        extraData: clickDaEventData,
      })
      clickDaEventData = null
    }
  })


  const exposeFilterCheckTagDaEvent = (showType, label) => {
    return {
      id: '1-8-3-24',
      once: false,
      data: {
        show_type: showType || 'default_outside',
        label_id: label?.labelId,
      }
    }
  }

  const clickFilterCheckTagDaEvent = (showType, label) => {
    const extraData = {
      label_id: label?.labelId,
      is_cancel: label?.isSelect == '1' ? 0 : 1,
      show_type: showType || 'default_outside',
      slt_num: '-',
    }

    if (label.isSelect == '1') {
      daEventCenter.triggerNotice({
        daId: '1-8-3-25',
        extraData,
      })
    } else {
      clickDaEventData = extraData
    }
  }

  return {
    exposeFilterCheckTagDaEvent,
    clickFilterCheckTagDaEvent,
  }
}
