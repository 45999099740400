import { daEventCenter } from 'public/src/services/eventCenter/index'

export const click_findsimilar = (data, callback) => {
  const defaultData = {
    sku: data.product.goods_sn,
    goods_id: data.product.goods_id,
    activeFrom: 'cart',
    code: 'cart',
    isOutOfStock: 1,
  }
  daEventCenter.triggerNotice({ daId: '1-8-3-6', extraData: callback?.(defaultData) ?? defaultData })
}

export const expose_findsimilar = (data, callback) => {
  const defaultData = {
    goods_id: data.product.goods_id,
    activity_from: 'cart',
    is_out_of_stock: 1
  }
  daEventCenter.triggerNotice({ daId: '1-8-3-5', extraData: callback?.(defaultData) ?? defaultData })
}
