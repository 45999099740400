import { daEventCenter } from 'public/src/services/eventCenter/index'
import { daEventCenter as daEventCenterV2 } from '@shein-aidc/basis-sa-event-center'

export const click_ranking_list_label = (data, callback) => {
  const defaultData = {
    goods_id: data?.aggregateProductBusiness?.productRowBiData?.rankListCartProductTagBiData?.goodsId,
    src_module: 'trade_label',
    ranking_list_identifier: data?.aggregateProductBusiness?.productRowBiData?.rankListCartProductTagBiData?.rankListIdentifier,
    board_generate_type: data?.aggregateProductBusiness?.productRowBiData?.rankListCartProductTagBiData?.boardGenerateType,
  }
  daEventCenterV2.triggerNotice({ id: 'click_ranking_list_label.page_cart', extraData: callback?.(defaultData) ?? defaultData })
}
