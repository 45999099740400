import { click_findsimilar, expose_findsimilar } from 'public/src/pages/cart_v2/analysis/item/findSimilarText.js'
import SimilarInstance from 'public/src/pages/common/similar_product_modal/index.js'
import { useUpdateModalState } from 'public/src/pages/cart_v2/utils/popoverManager/useUpdateModalState.js'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore'

/**
 * FindSimilarText 原子组件操作
 */
export function useFindSimilarTextEvent() {
  const { registerModalState, updateModalState } = useUpdateModalState()
  const { dispatch } = useStore()
  /**
   * 点击
   */
  const onClick = ({ itemOpts: { data }, analysis = {} }) => {
    SimilarInstance.showModal(data.value.product, {
      similarFrom: 'out_of_stock',
      statusCb(val) {
        if (val) {
          registerModalState()
        } else {
          updateModalState()
        }
      },
      handleAddToCartDone(data) {
        if (data && data.isSuccess) {
          dispatch('fetchCartIndex')
        }
      },
    })
    click_findsimilar(data.value, analysis.click_findsimilar)
  }

  /**
   * 点击
   */
  const onExpose = ({ itemOpts: { data }, analysis = {} }) => {
    expose_findsimilar(data.value, analysis.expose_findsimilar)
  }

  return {
    onClick,
    onExpose,
  }
}

