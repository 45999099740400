import { computed } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'
import popoverManager from './PopoverManager.js'
import cartGuideManager from 'public/src/pages/cart_v2/components/cartGuideManager/index.js'
import checkoutIncentive from 'public/src/pages/cart_v2/components/cartCheckoutIncentive/checkoutIncentive.js'
import debug from './useDebug'

export function useUpdateModalState({ moduleName } = {}) {
  const { commit, state } = useStore('', moduleName)
  const count = computed(() => state.popoverManagerModalCount)

  const increment = () => {
    commit('updateState', {
      key: 'popoverManagerModalCount',
      value: count.value + 1,
    })
  }
  const decrement = () => {
    commit('updateState', {
      key: 'popoverManagerModalCount',
      value: Math.max(count.value - 1, 0),
    })
  }

  // 注册模态框
  const registerModalState = function() {
    increment()
    checkoutIncentive.pause()
    popoverManager.setGlobalCondition('isModalOpen', true)
    debug && console.warn('气泡全局状态新增注册同步模态框')
  }

  // 更新模态框状态
  const updateModalState = function() {
    decrement()
    const isShow = count.value > 0
    popoverManager.setGlobalCondition('isModalOpen', isShow)
    if (!isShow) {
      // 关闭弹窗再次触发引导
      cartGuideManager.startGuide()
      checkoutIncentive.restart()
      debug && console.warn(`气泡全局状态更新注册关闭所有弹窗，重新调用异步队列`)
      popoverManager.scheduleTasks()
    }
  }

  // 重置模态框状态
  const resetModalState = function() {
    commit('updateState', {
      key: 'increment',
      value: 0,
    })
    popoverManager.setGlobalCondition('isModalOpen', true)
    debug && console.warn('气泡全局状态更新重置弹窗')
  }

  return {
    registerModalState,
    updateModalState,
    resetModalState,
  }
}
