<template>
  <div
    v-show="showCountDown"
    class="new-down-time"
    :class="[{active: diffDays == 0}]"
  >
    <div class="time-content">
      <span v-if="showEndTips">
        {{ endInText }}&nbsp;
      </span>
      <span
        v-show="diffDays"
        :class="[
          mode === 'block' && 'count-number',
          'count-down-days'
        ]"
      >{{ daysComputed }}&nbsp;</span>
      <span
        :class="[
          mode === 'block' && 'count-number',
          'count-down-hours'
        ]"
        :style="{fontSize: fontSize}"
      >{{ twoNums(diffHours) }}</span>
      <span v-html="delimiter"></span>
      <span
        :class="[
          mode === 'block' && 'count-number',
          'count-down-mins'
        ]"
        :style="{fontSize: fontSize}"
      >{{ twoNums(diffMinutes) }}</span>
      <span v-html="delimiter"></span>
      <span
        :class="[
          mode === 'block' && 'count-number',
          'count-down-secs'
        ]"
        :style="{fontSize: fontSize}"
      >{{ twoNums(diffSeconds) }}</span>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { useStore } from '../../hooks/useStore'
import { useTimeEndRefresh } from 'public/src/pages/cart_v2/hooks/useTimeEndRefresh.js'

export default defineComponent({
  name: 'CountDownTime',
  props: {
    mode: {
      type: String,
      default: 'block', // block/text
    },
    countDown: {
      type: [Object, Number],
      required: false,
      default: 0
    },
    timeStamp: {
      type: Number,
      required: false,
      default: 0
    },
    isLeftTime: {
      type: Boolean,
      required: false,
      default: false
    },
    fontSize: {
      type: String,
      default: '12px'
    },
    delimiter: {
      type: String,
      default: '&nbsp;:&nbsp;',
    },
    // 倒计时归零时，不隐藏倒计时
    notHide: Boolean,
    // 倒计时归零时，不刷新页面
    notReload: Boolean,
    moduleName: {
      type: String,
      default: '',
    },
    showEndTips: {
      type: Boolean,
      default: false
    },
    showDiffDays: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      diffDays: '',
      diffHours: '',
      diffMinutes: '',
      diffSeconds: '',
      timer: null,
      showCountDown: true
    }
  },
  setup(props) {
    const { state } = useStore('', props.moduleName)
    const { refreshHanlder } = useTimeEndRefresh(props.moduleName)
    return {
      language: state.language,
      refreshHanlder,
    }
  },
  computed: {
    daysComputed () {
      const { diffDays, language } = this
      if (diffDays > 1) {
        return `${diffDays} ${language.SHEIN_KEY_PWA_17012 || 'Days'}`
      } else if (diffDays == 1) {
        return `${diffDays} ${language.SHEIN_KEY_PWA_15291 || 'Day'}`
      } else {
        return ''
      }
    },
    endInText () {
      if (this.language && this.language.SHEIN_KEY_PWA_16271) {
        return this.language.SHEIN_KEY_PWA_16271.replace('{0}', '')
      } else {
        return ''
      }
    }
  },
  watch: {
    // 到手价倒计时需求中，timeStamp会随着勾选商品变化，此时需要重新计算倒计时
    timeStamp () {
      this.init()
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }   
  },
  activated(){
    this.init()
  },
  deactivated(){
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }   
  },
  methods: {
    init () {
      const countDownData = this.timeStamp ? countDownTime({ endTimestamp: this.isLeftTime ? (Date.now() + (this.timeStamp * 1000)) : this.timeStamp }) : typeof this.countDown == 'object' ? this.countDown : countDownTime({ endTimestamp: this.countDown })
      this.diffDays = this.showDiffDays ? countDownData.diffDays : 0
      this.diffHours = this.showDiffDays ? +countDownData.diffHours : +countDownData.diffHours + (+countDownData.diffDays * 24 )
      this.diffMinutes = +countDownData.diffMinutes
      this.diffSeconds = +countDownData.diffSeconds
      if(countDownData.diffTime <= 0) { // init found count down is <= 0, do not render countdown
        this.showCountDown = false
      }
      this.setTimer()
    },
    setTimer () {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.diffSeconds - 1 >= 0) {
            this.diffSeconds = this.diffSeconds - 1
          } else {
            if (this.diffMinutes - 1 >= 0) {
              this.diffMinutes = this.diffMinutes - 1
              this.diffSeconds = 59
            } else {
              if (this.diffHours - 1 >= 0) {
                this.diffHours = this.diffHours - 1
                this.diffMinutes = 59
                this.diffSeconds = 59
              } else {
                if (this.diffDays - 1 >= 0) {
                  this.diffDays = this.diffDays - 1
                  this.diffHours = 24
                  this.diffMinutes = 59
                  this.diffSeconds = 59
                } else {
                  clearInterval(this.timer)
                  if (!this.notHide) this.showCountDown = false
                  if (!this.notReload) this.refreshHanlder()
                  this.$emit('countDownEnd')
                }
              }
            }
          }
        }, 1000)
      }
    },
    twoNums (val) {
      if (typeof val === 'number' && !isNaN(val)) {
        return val < 10 ? `0${val}` : `${val}`
      } else {
        return ''
      }
    }
  },
})

/**
 * 计算倒计时
 */
function countDownTime ({ endTimestamp = 0 }) {
  let end = endTimestamp || 0
  if(end){
    end = (end + '').length >= 13 ? end : end * 1000
  }
  const now = (new Date()).getTime()
  const diffTime = end - now
  const diffDays = Math.floor(diffTime / 1000 / 60 / 60 / 24) // 相差天数
  const diffHours = Math.floor(diffTime / 1000 / 60 / 60 - diffDays * 24) // 相差小时
  const diffMinutes = Math.floor(diffTime / 1000 / 60 - diffDays * 24 * 60 - diffHours * 60) // 相差分钟
  const diffSeconds = Math.floor(diffTime / 1000 - diffDays * 24 * 60 * 60 - diffHours * 60 * 60 - diffMinutes * 60) // 相差秒数

  return {
    diffTime,
    diffDays,
    diffHours,
    diffMinutes,
    diffSeconds
  }
}
</script>
<style lang="less">
.count-down-time{
  font-weight: normal;
  .font-dpr(24px);
  color: @sui_color_highlight;
  &.active{
    .time-content{
      font-weight: bold;
    }
  }
  span.count-number{
    vertical-align: baseline!important; /* stylelint-disable-line declaration-no-important */
  }
}
.new-down-time{
  .count-number{
    background: #fff;
    padding: 1/75rem 4/75rem;
  }
}
</style>
<style lang="less" scoped>
.time-content {
  /* rtl:begin:ignore */
  direction: ltr;
  display: inline-flex;
  align-items: center;
}
</style>
