import { ref, computed, watch, onMounted } from 'vue'
import { useStore } from '../../hooks/useStore'

export const useClubGiftPickEntry = () => {
  const { state } = useStore()
  const isRenderClubGiftPickEntry = ref(false)
  const isMounted = ref(false)
  const clubGiftPickEntryData = computed(() => state.promotionState.topPromotionInfo.find(item => item.type === 1))

  watch([isMounted, clubGiftPickEntryData], () => {
    if (isRenderClubGiftPickEntry.value) return
    isRenderClubGiftPickEntry.value = isMounted.value && !!clubGiftPickEntryData.value
  })

  onMounted(() => {
    setTimeout(() => {
      isMounted.value = true
    }, 1000)
  })

  return {
    isRenderClubGiftPickEntry,
    clubGiftPickEntryData,
  }
}
