import { getImageRatio } from 'public/src/pages/goods_detail_v2/utils/common.js'
import { getQuickAddCartInstance } from 'public/src/pages/components/product/quick_add_v3/common'
import { useStore } from './useStore'
import useLoadingWithDelay from './useLoadingWithDelay'

const getSelectedAttrIndex = ({ attr, sku_sale_attr }) => {
  const selectedAttrIndex = {}
  if (attr) {
    selectedAttrIndex[attr.attr_id] = { attr_value_id: attr.attr_value_id }
  }
  if (sku_sale_attr) {
    sku_sale_attr.forEach(item => {
      if(item.is_main != '1' && !selectedAttrIndex[item.attr_id]) {
        selectedAttrIndex[item.attr_id] = { attr_value_id: item.attr_value_id }
      }
    })
  }
  return selectedAttrIndex
}

export const useQuickAddCart = () => {
  let quickAddCartInstance

  const { state, commit, dispatch } = useStore()

  const { doRequest } = useLoadingWithDelay(
    () => dispatch('fetchCartIndex'),
    (show) => {
      commit('changeRootSLoadingStatus', {
        show,
        maskTransparent: true,
        type: 'curpage',
      })
    }
  )

  const onAddCartComplete = (data) => {
    if (data?.isSuccess) {
      doRequest()
    }
  }

  const openQuickAddCart = async (item, options) => {
    const { goods_id, mall_code, goods_img, attr, sku_sale_attr } = item
    const { plugins = [], featureConfig = {}, callbacks, analysisConfig } = options || {}

    let imgRatio = null
    if (goods_img) imgRatio = await getImageRatio(item.goods_img)

    const postData = {
      label_id: state.filterState.selectFilterLabel?.labelId || 'all',
    }

    quickAddCartInstance = getQuickAddCartInstance()

    quickAddCartInstance.open(
      {
        goods_id,
        mallCode: mall_code || '',
      },
      {
        plugins: [
          ...plugins,
        ],
        featureConfig,
        callbacks: {
          onAddCartComplete,
          ...callbacks,
        },
        salesAttrConfig: {
          selectedAttrIndex: getSelectedAttrIndex({ attr, sku_sale_attr }),
        },
        extendedParam: {
          imgRatio,
        },
        excludePlugins: ['syncCartInfo'],
        analysisConfig: {
          index: 0,
          code: 'cart',
          from: 'cart',
          postData,
          ...analysisConfig,
        },
      }
    )
  }

  const closeQuickAddCart = () => {
    quickAddCartInstance?.close()
  }

  return {
    openQuickAddCart,
    closeQuickAddCart,
    onAddCartComplete,
  }
}
