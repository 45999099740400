import { computed } from 'vue'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { getImageRatio } from 'public/src/pages/goods_detail_v2/utils/common.js'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore'
import { useQuickAddCart } from 'public/src/pages/cart_v2/hooks/useQuickAddCart.js'
import { expose_update_popup, click_update_popup_changemall, click_goods_size_select, click_update_popup_updatebutton } from 'public/src/pages/cart_v2/analysis/item/goodsSaleAttr'
import { getQuickAddCartInstance, plugins } from 'public/src/pages/components/product/quick_add_v3/common'
import { useUpdateModalState } from 'public/src/pages/cart_v2/utils/popoverManager/useUpdateModalState.js'
import { useGuideCheckoutStateChange } from 'public/src/pages/cart_v2/components/cartItemGuideCheckout/useGuideCheckoutStateChange.js'

export function useGoodsSaleAttrEvent() {
  const { state, commit, dispatch } = useStore()
  const { registerModalState, updateModalState } = useUpdateModalState()
  const { openQuickAddCart, closeQuickAddCart } = useQuickAddCart()
  const { handleActiveItemChange, handleIsShowPopoverTimeChange } = useGuideCheckoutStateChange()
  const language = computed(() => state.language)
  const monitorUserInteraction = computed(() => state?.cart?.monitorUserInteraction)

  const getSelectedAttrIndex = (data) => {
    const selectedAttrIndex = {}
    const attr = data.value.attr || null
    const sku_sale_attr = data.value.product?.sku_sale_attr || []
    if(attr) {
      selectedAttrIndex[attr.attr_id] = { attr_value_id: attr.attr_value_id }
    }
    sku_sale_attr && sku_sale_attr.forEach(item => {
      if(item.is_main != '1' && !selectedAttrIndex[item.attr_id]) {
        selectedAttrIndex[item.attr_id] = { attr_value_id: item.attr_value_id }
      }
    })
    return selectedAttrIndex
  }
  const onUpdated = async ({ data, context, events, isShowOverLimitToast = false }) => {
    try {
      const reqParams = {
        is_checked: data.value.is_checked,
        append_id_list: data.value.appendIds,
        sku_code: context?.skuInfo?.sku_code,
        id: data.value.id,
        mall_code: context?.mallCode,
        quantity: context?.quantity,
        goods_id: context?.productInfo?.goods_id,
      }
      const res = await dispatch('fetchCartUpdateAttr', reqParams)
      if (res.code == '0') {
        dispatch('showOverLimitToast', { id: data.value.id, isShowOverLimitToast })
        events?.onUpdated?.()
      } else {
        SToast(res.msg)
      }
      click_update_popup_updatebutton(data.value, (defaultData) => {
        return {
          ...defaultData,
          update_result: res.code === '0' ? 1 : 0
        }
      })
      monitorUserInteraction.value?.stop?.({ reason: 'edit goods properties' })
    } catch (e) {
      console.error(e)
    }
  }
  const onClick = async (
    {
      itemOpts: { data, event },
      options: {
        index, 
        code = 'cart',
        from = 'cart',
        source = 'page',
        isShowWishBtn = false,
        isShowOverLimitToast = false,
        events
      } = {},
      analysis
    }
  ) => {
    if (event.isCustomGoods && event.event === 'attr') {
      SToast(language.value.SHEIN_KEY_PWA_26445)
      return
    }
    if (event.event === 'select') {
      openQuickAddCart(
        {
          goods_id: data.value.product.goods_id,
          mall_code: data.value.mall_code,
          goods_img: data.value.product.goods_img,
          attr: data.value.attr,
          sku_sale_attr: data.value.product.sku_sale_attr,
        },
        {
          featureConfig: {
            footerBar: {
              needFavoriteButton: false,
            },
          },
          callbacks: {
            onAddCartFinished: (data) => {
              if (!data) return
              if (data.isSuccess) {
                const cartId = data.addCartResponse?.info?.cart?.id
                if (cartId) commit('addWishCartIds', cartId)
              } else {
                const errCode = data.addCartResponse?.code
                // 超出购物车最大容量
                if (errCode === '300407') {
                  closeQuickAddCart()
                  dispatch('fetchCartIndex')
                }
              }
            },
          },
          analysisConfig: {
            index,
            code,
            from,
          },
        }
      )
      click_goods_size_select(data.value, analysis?.click_goods_size_select)
      return
    }
    // 付费会员赠品重选
    const payMemberGiftView = data.value.aggregateProductBusiness?.cartRowViewControl?.payMemberGiftView
    if (payMemberGiftView?.promotionId && event.event === 'reselect') {
      commit('changeAppendageDrawerStatus', { promotionId: payMemberGiftView.promotionId })
      return
    }
    click_goods_size_select(data.value, analysis?.click_goods_size_select)
    const imgRatio = await getImageRatio(data.value.product.goods_img)
    // 未勾选商品打开编辑属性弹窗，属正向交互，关闭时可能出现引导结算气泡（不关注是否更新成功）
    if (data.value.is_checked === '0') {
      handleActiveItemChange(data.value)
    }
    getQuickAddCartInstance().open(
      {
        goods_id: data.value.product.goods_id,
        mallCode: data.value.mall_code,
      },
      {
        plugins: [
          plugins.salesAttrUpdatePlugin(async context => {
            await onUpdated({ data, context, events, isShowOverLimitToast })
          })
        ],
        excludePlugins: ['syncCartInfo'],
        analysisConfig: {
          index,
          code,
          from,
          source,
        },
        salesAttrConfig: {
          selectedAttrIndex: getSelectedAttrIndex(data)
        },
        extendedParam: {
          imgRatio,
          quantity: +data.value.quantity
        },
        featureConfig: {
          footerBar: {
            // 是否需要收藏按钮
            needFavoriteButton: isShowWishBtn,
          },
        },
        callbacks: {
          onDrawerStatusChange: (show) => {
            if (show) {
              expose_update_popup(data.value, analysis?.expose_update_popup)
              registerModalState()
            } else {
              updateModalState()
              // 关闭更新属性弹窗，到达引导结算气泡展示时机
              handleIsShowPopoverTimeChange(true)
            }
          },
          onMallClick: () => {
            click_update_popup_changemall(data.value, analysis?.click_update_popup_changemall)
          },
        }
      }
    )
  }

  return {
    onClick
  }
}
