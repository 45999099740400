import { daEventCenter } from 'public/src/services/eventCenter/index'

export const expose_goods_userbehaviortips = (data, callback) => {
  const info = data?.aggregateProductBusiness?.productTips?.find(tag => tag.type === 'lowStockAndUserBehavior') || null
  const defaultData = {
    userbehavior_tips: (info?.data?.lowStockAndUserBehavior?.filter(label => label.actionDataTagShow > 0  && label.tagType != 'lowStock') ?? [])?.map((v) => v.tagId)?.join(','),
    userbehavior_tips_show: '',
    goods_id: data?.product?.goods_id,
  }
  daEventCenter.triggerNotice({ daId: '1-8-1-40', extraData: callback?.(defaultData) ?? defaultData })
}
