import { click_store_name, expose_store_name } from 'public/src/pages/cart_v2/analysis/item/goodsStore.js'
import { markPoint } from 'public/src/services/mark/index.js'
import { useRouter } from 'public/src/pages/cart_v2/hooks/useRouter.js'

export function useGoodsStoreEvent() {
  const { routerPush } = useRouter()

  const onExpose = ({ itemOpts: { data } }) => {
    expose_store_name(data.value)
  }
  const onClick = ({ itemOpts: { data } }) => {
    const storeRouting = data.value.storeRouting
    if (storeRouting) {
      markPoint('toNextPageClick', 'public')
      routerPush(`${gbCommonInfo.langPath}${storeRouting}`)
      click_store_name(data.value)
    }
  }

  return {
    onExpose,
    onClick,
  }
}
