import { ref, reactive, onMounted, onUnmounted, computed } from 'vue'
import schttp from 'public/src/services/schttp'
import { debounce, cloneDeep } from '@shein/common-function'

/**
 * 获取包邮凑单推荐商品
 * 接口文档：https://soapi.sheincorp.cn/application/7940/versions/test64/get_order_cart_collect_bills_recommend_products/doc
 *
 */
const fetchRecommends = async (params) => {
  return schttp({
    url: '/order/cart_collect_bills_recommend_products',
    method: 'GET',
    params,
    useBffApi: true,
  })
}

const defaultOpts = {
  adp: '',
  cate_ids: '',
  entranceScene: '',
  exclude_tsp_id: '',
  include_tsp_id: '',
  goods_ids: '',
  goods_price: '',
  limit: 20,
  main_goods_id: '',
  mall_code: '',
  page: 1,
  pageSceneBizCode: '',
  stock_enough: '1',
  typeid: '',
  sort: 0,
  filterSoldOut: '0',
}

export default function useRecommends(options = {}, config = {}) {
  // 合并参数
  const mergeOpts = { ...defaultOpts, ...options }

  // data
  const list = ref([])  // 商品列表数据
  const loading = ref(true)  // 加载状态
  const error = ref(null) // 错误信息
  const total = ref(0) // 总商品数

  const initialParams = { ...mergeOpts } // 保存初始状态以便重置
  
  // 包装查询参数，便于修改
  const params = reactive(cloneDeep(initialParams))

  // 分页信息
  const pagination = computed(() => ({
    page: params.page,
    limit: params.limit,
  }))

  // 请求控制器 - 取消请求
  let controller = null

  const cancelFetch = () => {
    if (controller) {
      controller.abort()
      controller = null
    }
  }

  // 商品数据获取住函数
  const fetchProducts = async () => {

    cancelFetch() // 如果有请求先取消
    controller = new AbortController()

    loading.value = true
    error.value = null
    try {
      const res = await fetchRecommends(params)
      if (res.code == 0) {
        const { products, num }  = res?.info
        list.value = products || []
        total.value = num || 0
      } else {
        error.value = res
      }
      // 
    } catch (err) {
      // 忽略取消请求的错误
      if (err.name !== 'AbortError') {
        console.error('Error fetching:', err)
      }
    } finally {
      loading.value = false
      controller = null
    }
  }

  // 控制频率
  const debouncedFetch = debounce({
    func: fetchProducts,
    wait: 300,
  })

  const updateParams = (newParams) => {
    Object.keys(newParams).forEach(key => {
      if (key in params) {
        params[key] = newParams[key]
      }
    })

    debouncedFetch()
  }
  
  // 切换页码
  const goPage = (val) => {
    updateParams({ page: val })
  }

  // 设置排序
  const setSort = (val) => {
    updateParams({ sort: val })
  }

  // 设置筛选
  // const setFilters = (newFilters) => {
  // }

  const reset = () => {
    cancelFetch() // 取消请求

    // 清空数据状态
    list.value = []
    total.value = 0
    loading.value = false
    error.value = null

    // 重置所有参数到初始值
    updateParams({ ...initialParams })
  }

  onMounted(() => {
    if (config?.immediate) {
      fetchProducts()
    }
  })
  onUnmounted(() => {
    cancelFetch()
    debouncedFetch.cancel?.()
  })
  
  return {
    list,
    loading,
    error,
    total,
    pagination,
    
    // 方法
    fetchProducts,
    goPage,
    setSort,
    reset,
  }
}
