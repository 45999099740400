<template>
  <div 
    class="comments-wrapper"
    :style="style"
  >
    <div
      ref="commentsRef"
      class="comment-list"
    >
      <div class="row">
        <CommentItem
          v-for="(comment, i) in evenItems"
          :key="i"
          :comment="comment"
        />
      </div>
      <div class="row">
        <CommentItem
          v-for="(comment, i) in oddItems"
          :key="i"
          :comment="comment"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, nextTick, onMounted, defineExpose } from 'vue'
import CommentItem from './CommentItem.vue'
import { gsap } from 'gsap'
const { GB_cssRight } = gbCommonInfo
let tl = null

const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
})

const isActive = ref(false)
const imgRect = ref({})
const commentsRef = ref(null)

const commentList = computed(() => {
  return props.item.aggregateProductBusiness?.productBulletScreens || []
})
const oddItems = computed(() => {
  return commentList.value.filter((item, i) => i % 2 !== 0)
})
const evenItems = computed(() => {
  return commentList.value.filter((item, i) => i % 2 === 0)
})
const gapDistance = computed(() => {
  return (imgRect.value?.width || 0) * (3 / 4)
})
const style = computed(() => {
  const { left, width } = imgRect.value || {}
  return {
    '--var-left': ((left - 6) || 0) + 'px',
    '--var-width': width + 'px',
    '--var-distance': gapDistance.value + 'px',
  }
})

onMounted(() => {
  nextTick(() => {
    getImgEl()
  })
})

const getImgEl = (() => {
  setTimeout(() => {
    const imgEl = document.querySelector(`.j-cart-item-${props.item.id} .bsc-cart-item-goods-img__content`)
    if (!imgEl) return 
    imgRect.value = imgEl.getBoundingClientRect()    
  }, 100)
})

const startBarrageAnimation = () => {
  if (!commentsRef.value?.parentElement) return
  tl = gsap.timeline()
  const widthRatio = (imgRect.value.width / commentsRef.value.clientWidth) * 100 + '%'
  const fromX =  GB_cssRight ? `-${widthRatio}` : widthRatio
  const toX = GB_cssRight ? '100%' : '-100%'
  isActive.value = true
  return new Promise((resolve) => {
    if (!tl) resolve()
    tl.set(commentsRef.value.parentElement, { opacity: 1, height: `${36 / 37.5}rem` })
      .fromTo(commentsRef.value, { x: fromX }, { 
        x: toX,
        duration: 3,
        ease: 'none',
        onComplete: () => {
          resolve()
          tl.kill()
        }
      })
      .set(commentsRef.value.parentElement, { opacity: 0, height: 0 })
  })
}

const endBarrageAnimation = () => {
  isActive.value = false
  if (tl) {
    tl.seek(10, false) // 进度拉到10s，参数false代表不阻止回调事件onComplete
    tl.kill()
  }
}

defineExpose({
  startBarrageAnimation,
  endBarrageAnimation,
  isActive,
})
</script>
<style lang="less" scoped>
.comments-wrapper {
  position: absolute;
  top: 16/37.5rem;
  left: var(--var-left)/*rtl:ignore*/;
  width: var(--var-width);
  z-index: 1;
  overflow: hidden;
  opacity: 0;
  .comment-list {
    position: absolute;
    height: 100%;
    direction: ltr;
  }
  .row {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 6/37.5rem;
    }
    &:nth-child(2) {
      margin-left: var(--var-distance)
    }
  }
}
</style>
