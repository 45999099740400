import { computed } from 'vue'
import { DirectlyAddCartPlugin } from '@shein-aidc/bs-quick-add-cart-standard-mobile'
import { useStore } from './useStore.js'
import { useQuickAddCart } from './useQuickAddCart.js'
import { click_select_someproduct } from 'public/src/pages/cart_v2/analysis/checkbox'

export default function useModifyWishCheckStatus() {
  const { state, commit, dispatch } = useStore()
  const selectFilterLabel = computed(() => state.filterState.selectFilterLabel)
  const { openQuickAddCart, closeQuickAddCart, onAddCartComplete } = useQuickAddCart()

  const onAddCartError = (data) => {
    // 超出购物车最大容量
    if (data?.code === '300407') {
      closeQuickAddCart()
      dispatch('fetchCartIndex')
    }
  }

  const modifyWishCheckStatus = async ({ item, index }) => {
    click_select_someproduct(item, (defaultData) => {
      const data = { ...defaultData, label_id: selectFilterLabel.value?.labelId }
      return data
    })
    openQuickAddCart(
      {
        goods_id: item.product.goods_id,
        mall_code: item.mall_code,
        goods_img: item.product.goods_img,
        attr: item.attr,
        sku_sale_attr: item.product.sku_sale_attr,
      },
      {
        plugins: [
          DirectlyAddCartPlugin(
            () => !!(item.product?.color_image || item.aggregateProductBusiness?.goodsAttr),
            (data) => {
              if (data?.code === '0') {
                const cartId = data.info?.cart?.id
                if (cartId) commit('addWishCartIds', cartId)
                // 一键加车需要自己调用 onAddCartComplete
                onAddCartComplete({ isSuccess: true })
              }
            },
            { sku_code: item.product?.sku_code },
          ),
        ],
        featureConfig: {
          footerBar: {
            needFavoriteButton: false,
          },
        },
        callbacks: {
          onAddCartFinished: (data) => {
            if (!data) return
            if (data.isSuccess) {
              const cartId = data.addCartResponse?.info?.cart?.id
              if (cartId) commit('addWishCartIds', cartId)
            } else {
              onAddCartError(data.addCartResponse)
            }
          },
        },
        analysisConfig: {
          index,
        },
      },
    )
  }

  return {
    modifyWishCheckStatus,
  }
}
