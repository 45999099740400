import { ref, computed, watch } from 'vue'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'
import { useGuideCheckoutStateChange } from './useGuideCheckoutStateChange'
import { useItemDisplayView } from './useItemDisplayView.js'
import { CART_ITEM_GUIDE_CHECKOUT } from 'public/src/pages/cart_v2/utils/popoverManager/popoverTypes.js'
import { usePopoverManager } from 'public/src/pages/cart_v2/utils/popoverManager/usePopoverManager.js'

export const useCartItemGuideCheckout = () => {
  const { commit, state } = useStore()
  const { handlePopoverShowChange } = useGuideCheckoutStateChange()

  const showPopover = computed(() => state.cartItemGuideCheckoutState.showPopover)
  const activePopoverCartId = computed(() => state.cartItemGuideCheckoutState.activePopoverCartId)

  // 业务逻辑相关配置
  const productLineLureBubbleConfig = computed(() => state.cartConfigs?.productLineLureBubbleConfig || {
    maxNumBubble: '10', // 最大展示次数
    intervalTime: '30', // 时间间隔
    oneTimeBubble: '3', // 气泡展示时间
  })
  // 当前操作的商品行id
  const currentActiveItemId = computed(() => state.cartItemGuideCheckoutState.currentActiveItemId)
  // 当前操作的商品行信息
  const cartItemMap = computed(() => state.cartItemMap)
  const currentActiveItem = computed(() => cartItemMap.value?.[currentActiveItemId.value] || {})
  const productLineLureBubbleInfo = computed(() => currentActiveItem.value?.aggregateProductBusiness?.productLineLureBubbleInfo || {})
  // 气泡相关缓存
  const cache = computed(() => state.cartItemGuideCheckoutState.cache)

  // 中间层有返回气泡信息
  const hasBubbleInfo = computed(() => !!productLineLureBubbleInfo.value?.text)
  // 是否到达展示时机
  const isShowPopoverTime = computed(() => state.cartItemGuideCheckoutState.isShowPopoverTime)

  // 基础展示条件(数据+交互)：中间层有返回气泡信息 && 到达展示时机
  const baseShowConditions = computed(() => Boolean(hasBubbleInfo.value && isShowPopoverTime.value))
  // 所有基础展示条件(数据+交互+配置)：中间层有返回气泡信息 && 达到展示时机 && 触发次数未超出 && 距离上次出现气泡的时间间隔已满足 && 当前商品行未出现过气泡
  let baseShowAllConditions = ref(false)

  watch(() => baseShowConditions.value, (val) => {
    if (val) {
      const triggeredCountExceeded = triggeredCountExceededFn()
      const isTimeIntervalSatisfied = isTimeIntervalSatisfiedFn()
      const currentItemTriggered = currentItemTriggeredFn()
      if (!triggeredCountExceeded && isTimeIntervalSatisfied && !currentItemTriggered) {
        baseShowAllConditions.value = true
      } else {
        handlePopoverShowChange(false)
      }
    }
  })

  watch(() => showPopover.value, (newVal) => {
    if (!newVal) {
      baseShowAllConditions.value = false
    }
  })

  // 接入气泡优先级管理
  const { itemInVisible, updateItemInVisible } = useItemDisplayView()
  const { isActivePopover, deactivatePopover, activatePopover, registerPopover } = usePopoverManager({
    type: CART_ITEM_GUIDE_CHECKOUT,
  })
  registerPopover()

  watch(() => baseShowAllConditions.value, (val) => {
    if (val && currentActiveItemId.value) {
      updateItemInVisible(currentActiveItemId.value)
      activatePopover()
    }
  })

  watch(() => isActivePopover.value, (val) => {
    if (val) {
      updateItemInVisible(currentActiveItemId.value)
    }
  })

  const isShow = computed(() => {
    return Boolean(baseShowAllConditions.value && isActivePopover.value && itemInVisible.value)
  })

  const biDataType = computed(() => productLineLureBubbleInfo.value?.biDataType || '')
  const autoCloseTime = computed(() => +productLineLureBubbleConfig.value?.oneTimeBubble * 1000)

  watch(() => isShow.value, (val) => {
    if (val) {
      handlePopoverShowChange(true)
      daEventCenterExpose(biDataType.value)
      updateCacheInfo()
      state.cartItemGuideCheckoutState.popoverTimer = setTimeout(() => {
        handlePopoverShowChange(false)
        deactivatePopover()
      }, autoCloseTime.value)
    } else {
      deactivatePopover()
    }
  })

  // 触发次数是否超出
  const triggeredCountExceededFn = () => {
    const currentTriggeredCount = +cache.value?.TRIGGERED_COUNT
    return currentTriggeredCount >= +productLineLureBubbleConfig.value.maxNumBubble
  }
  // 是否满足时间间隔
  const isTimeIntervalSatisfiedFn = () => {
    const lastTriggeredTimestamp = +cache.value?.LAST_TRIGGERED_TIMESTAMP
    const currentTimeStamp = Date.now()
    return currentTimeStamp - lastTriggeredTimestamp >= +productLineLureBubbleConfig.value.intervalTime * 1000
  }
  // 当前商品行是否已触发过
  const currentItemTriggeredFn = () => {
    const triggeredItemIds = cache.value?.TRIGGERED_ITEM_IDS || []
    return triggeredItemIds?.includes(currentActiveItemId.value)
  }


  // 引导结算气泡曝光
  const daEventCenterExpose = (biDataType) => {
    daEventCenter.triggerNotice({
      id: 'expose_benefit_pop_cartgds.page_cart',
      extraData: {
        actual_point: biDataType || '-',
        goods_id: currentActiveItem.value?.goodId,
      },
    })
  }

  // 更新缓存信息
  const updateCacheInfo = () => {
    let triggeredItemIds = cache.value?.TRIGGERED_ITEM_IDS || []
    let triggeredCount = +cache.value?.TRIGGERED_COUNT || 0
    let lastTriggeredTimestamp = +cache.value?.LAST_TRIGGERED_TIMESTAMP || 0

    triggeredItemIds?.push(activePopoverCartId.value)
    triggeredCount += 1
    lastTriggeredTimestamp = Date.now()

    commit('updateCartItemGuideCheckoutState', {
      cache: {
        TRIGGERED_ITEM_IDS: triggeredItemIds,
        TRIGGERED_COUNT: triggeredCount,
        LAST_TRIGGERED_TIMESTAMP: lastTriggeredTimestamp,
      },
    })
  }
}
