import { expose_goods_userbehaviortips } from 'public/src/pages/cart_v2/analysis/item/lowStockLabel.js'

/**
 * LowStockLabel 原子组件操作
 */
export function useLowStockLabelEvent() {

  /**
   * 曝光
   */
  const onLabelExpose = ({ itemOpts: { data, event }, analysis = {} }) => {
    const info = data.value?.aggregateProductBusiness?.productTips?.find(tag => tag.type === 'lowStockAndUserBehavior') || null
    const labels = info?.data?.lowStockAndUserBehavior?.filter(item => item.actionDataTagShow > 0 && item.tagType != 'lowStock') ?? []
    if (labels.length > 0) {
      expose_goods_userbehaviortips(data.value, (defaultData) => {
        const data = {
          ...defaultData,
          userbehavior_tips_show: event.exposeLabel.join(','),
        }
        return analysis.expose_goods_userbehaviortips?.(data) ?? data
      })
    }
  }

  return {
    onLabelExpose,
  }
}
