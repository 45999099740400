import { click_custom_content } from 'public/src/pages/cart_v2/analysis/item/goodsCustomContent.js'
import customDetailDialogBff from 'public/src/pages/common/customDetailDialogBff/index.js'
import { useUpdateModalState } from 'public/src/pages/cart_v2/utils/popoverManager/useUpdateModalState.js'

/**
 * GoodsCustomContent 原子组件操作
 */
export function useGoodsCustomContentEvent() {
  const { registerModalState, updateModalState } = useUpdateModalState()

  /**
   * 点击
   */
  const onClick = ({ itemOpts: { data, event }, analysis = {} }) => {
    customDetailDialogBff.open(event, registerModalState, updateModalState)
    click_custom_content(data.value, (defaultData) => {
      const data = {
        ...defaultData,
        images: event.images?.length || 0,
        preview: event.effects?.length || 0,
        texts: event.texts?.length || 0,
      }
      return analysis.click_custom_content?.(data) ?? data
    })
  }

  return {
    onClick,
  }
}

