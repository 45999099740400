const QUICK_SHIP_TAG_ID = '600061529' // qs快速发货
const LOW_STOCK_TAG_ID = '600078004' // 低库存

export const getPrice = (item) => {
  const fullPriceUI = item.productMaterial?.fullPriceUI
  if (fullPriceUI) {
    return {
      salePrice: fullPriceUI.price,
      salePriceShowStyle: fullPriceUI.priceShowStyle,
      unitDiscount: fullPriceUI.discountPercent
    }
  }

  return {
    salePrice: item?.salePrice?.amountWithSymbol,
    salePriceShowStyle: item?.salePrice?.priceShowStyle,
    unitDiscount: item?.unit_discount,
  }
}

/**
 * 获取优先级最高的腰带
 * 低库存 > qs > 销量
 */
export const getPriorityBelt = (item) => {
  const v2ProductAttributeLabelList = item?.productMaterial?.v2ProductAttributeLabelList || []
  const qsLabel = v2ProductAttributeLabelList.find(tag => tag.contentType === 'custom' && tag.tagId == QUICK_SHIP_TAG_ID)
  const lsLabel = v2ProductAttributeLabelList.find(tag => tag.contentType === 'custom' && tag.tagId == LOW_STOCK_TAG_ID)
  if (lsLabel) return lsLabel.labelLang
  if (qsLabel) return qsLabel.labelLang
  return item?.salesLabel?.labelLang || ''
}


/**
 * 获取优先级最高的标签
 * 低库存 > qs > 榜单 > 评分
 */
export const getPriorityLabel = (item) => {
  const v2ProductAttributeLabelList = item?.productMaterial?.v2ProductAttributeLabelList || []
  const qsLabel = v2ProductAttributeLabelList.find(tag => tag.contentType === 'custom' && tag.tagId == QUICK_SHIP_TAG_ID)
  const lsLabel = v2ProductAttributeLabelList.find(tag => tag.contentType === 'custom' && tag.tagId == LOW_STOCK_TAG_ID)
  const rankLabel = v2ProductAttributeLabelList.find(tag => tag.contentType === 'ranking')
  const commentsLabel = v2ProductAttributeLabelList.find(tag => tag.contentType === 'number_of_comments')
  if (qsLabel) {
    return {
      name: 'QuickShipTag',
      props: {
        text: qsLabel.labelLang,
        prefixIcon: 'https://img.ltwebstatic.com/v4/p/ccc/2025/03/14/3c/1741946131d6780239db8ac62b67f9754461261577.png',
      }
    }
  }
  if (lsLabel) {
    return {
      name: 'LowStockLabel',
      props: {
        text: lsLabel.labelLang,
        textColor: lsLabel.fontColor,
      }
    }
  }
  if (rankLabel && item?.rankInfo?.twoColumnStyle) {
    return {
      name: 'RankListTag',
      props: {
        text: item.rankInfo.twoColumnStyle.rankTypeText,
        suffixText: item.rankInfo.twoColumnStyle.composeIdText,
        isClick: false,
      }
    }
  }
  if (commentsLabel) {
    return {
      name: 'GoodsStarRating',
      props: {
        commentRankAverage: item?.comment_rank_average,
        commentNum: item?.comment_num_show,
      },
    }
  }
  return {}
}

export const getGoodsListBiInfo = (item, index = 0) => {
  const { goods_id, goods_sn, productRelationID, mall_code, productMaterial } = item
  return [
    goods_id, 
    goods_sn, 
    productRelationID, 
    index + 1, 
    1, 
    '', 
    '', 
    '', 
    (productMaterial?.fullPriceUI?.bi?.pri || ''), 
    (productMaterial?.fullPriceUI?.bi?.otherMarks || ''), 
    `mall_${mall_code}`
  ].join(`\``)
}
