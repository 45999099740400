import { ref, computed } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'

export function usePriceChangePopoverStyle() {
  const { state } = useStore()
  const showPopoverId = computed(() => state.priceChangeState.showPopoverId)
  const GB_cssRight = computed(() => state.locals?.GB_cssRight)
  const popoverStyle = ref({})

  const calcPopoverStyle = () => {
    const bscCartItemGoodsPriceLeft = document.querySelector(`.j-cart-list .j-cart-item-${showPopoverId.value} .bsc-cart-item-goods-price__left`)?.getBoundingClientRect()
    const cartSwiperSlide = document.querySelector(`.j-cart-list .j-cart-item-${showPopoverId.value} .cart-swiper-slide`)?.getBoundingClientRect()
    popoverStyle.value = {
      left: GB_cssRight.value ? cartSwiperSlide?.right - bscCartItemGoodsPriceLeft?.right  + 'px' : bscCartItemGoodsPriceLeft?.left - cartSwiperSlide?.left + 'px',
      top: bscCartItemGoodsPriceLeft?.top - cartSwiperSlide?.top + 'px',
    }
  }

  return {
    popoverStyle,
    calcPopoverStyle,
  }
}
