import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'

export function useHideComponents() {
  const { state, commit } = useStore()
  
  const isHideComponent = (name) => {
    return state.hideComponents.includes(name)
  }

  const registerMiniCartHider = (names = []) => {
    commit('updateState', {
      key: 'hideComponents',
      value: [
        'CartListFilter',
        'FilterAdditionalInfo',
        'CartAddItem',
        'EmbedLoading',
        'ClubGiftPickEntry',
        'soldoutItems',
        'NewGoodsRecommendPopover',
        'CartItemGuideAddOnPopover',
        'PriceChangePopover',
        'GoodsAlmostEntry',
        'GoodsNegativeInfo',
        'CartItemGuideAddOnFloor',
        'GoodsCustomContent',
        'LowEfficiencyGuideRecommended',
        'swiperFindSimilar',
        'swiperShare',
        'negativeInformation',
        'CartLowStockPopover',
        'CartGuideCheckoutPopover',
      ]
    })
  }

  return {
    isHideComponent,
    registerMiniCartHider,
  }
}
