import { getCurrentInstance } from 'vue'

const useRouter = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  return {
    router: vm.proxy.$router || (typeof window !== 'undefined' ? window._gb_app_.$router : {}),
    route: vm.proxy.$route || (typeof window !== 'undefined' ? window._gb_app_.$route : {}),
    routerGoWish: vm.proxy.$routerGoWish || (typeof window !== 'undefined' ? window._gb_app_.$routerGoWish : {}),
    routerPush: vm.proxy.$routerPush || (typeof window !== 'undefined' ? window._gb_app_.$routerPush : {}),
  }
}

export { useRouter }
