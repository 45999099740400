<template>
  <div
    v-expose="analysisData('expose_cartpromotionadd.page_cart')"
    class="bottom-common"
    @click="handleViewMore"
  >
    <Icon
      name="sui_icon_activity_18px"
    />
    <span
      class="tips"
      v-html="tips"
    ></span>
    <span class="view-more">
      {{ language.SHEIN_KEY_PWA_22740 }}
    </span>
  </div>
</template>

<script>
import { expose } from 'public/src/pages/common/analysis/directive'
import { defineComponent, computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { template } from '@shein/common-function'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'

export default defineComponent({
  name: 'BottomCommon',
  directives: {
    expose,
  },
  setup() {
    const { state } = useStore()
    const language = computed(() => state.language)
    return {
      language
    }
  },
  components: {
    Icon,
  },
  inject: { fixedUptt: { default: () => {} } },
  props: {
    list: {
      type: Array,
      default: () => ([])
    },
    showWay: {
      type: String,
      default: '-',
    }
  },
  computed: {
    tips() {
      return template(`<em>${this.list?.length}</em>`, this.language.SHEIN_KEY_PWA_30499)
    },
  },
  mounted() {
    this.fixedUptt?.()
  },
  activated() {
    this.fixedUptt?.()
  },
  methods: {
    handleViewMore() {
      daEventCenter.triggerNotice(this.analysisData('click_cartpromotionadd.page_cart'))
      this.$emit('openHub')
    },
    analysisData(daId){
      return {
        id: daId,
        once: false,
        data: {
          show_way: this.showWay,
          newuser_label: '-',
          is_member_gift: 0,
          show_position: '2'
        }
      }
    }
  }
})
</script>

<style lang="less" scoped>
.bottom-common {
  position: relative;
  display: flex;
  align-items: center;
  background: url("//img.ltwebstatic.com/images3_acp/2024/03/26/f0/17114409959d41d7c4ef5f82a99e6f236c4e86916f.png") no-repeat;
  background-size: cover;
  height: 48/37.5rem;
  padding: 10/37.5rem 12/37.5rem;
  .tips {
    flex: 1;
    padding: 0 12/37.5rem 0 8/37.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    :deep(em) {
      font-weight: bold;
      color: #FA6338;
    }
  }
  .view-more {
    padding: 3/37.5rem 6/37.5rem;
    border: 0.5px solid rgba(0, 0, 0, 0.50);
    border-radius: 12px;
    font-weight: bold;
  }
}
</style>
