import { computed } from 'vue'
import { useStore } from './useStore'

export function useTimeEndRefresh(moduleName) {
  const { state, dispatch, commit } = useStore('', moduleName)
  
  const isRefreshHanlder = computed(() => state.isRefreshHanlder)

  const refreshHanlder = () => {
    if (isRefreshHanlder.value) {
      return
    }
    commit('updateState', {
      key: 'isRefreshHanlder',
      value: true,
    })
    dispatch('fetchCartIndex').finally(() => {
      commit('updateState', {
        key: 'isRefreshHanlder',
        value: false,
      })
    })
  }

  return {
    refreshHanlder
  }
}