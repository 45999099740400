const CACHE = {}

export const setCache = (key, value) => {
  CACHE[key] = value
  window.localStorage.setItem(key, value)
}

export const getCache = (key) => {
  // 内存优先
  // 测试喜欢手动删除 localStorage 缓存又不刷新页面
  return CACHE[key] || window.localStorage.getItem(key)
}
