<template>
  <div class="item-more-deals">
    <div 
      class="deals-content"
      :class="{ 'is-coupon': isCoupon }"
      :style="styleInfo"
    >
      <CornerTag
        v-if="item.rightCornerIcon"
        :img-src="item.rightCornerIcon"
        :text="item.rightCornerTip"
      />
      <div class="wrapper">
        <div class="left">
          <div class="top">
            <template v-if="isCoupon">
              <div class="top-l">
                <SAdapterText
                  class="coupon-face"
                  min-size="12"
                  :text="item.thresholdTip"
                />
                <p 
                  class="ellipsis coupon-threshold"
                >
                  {{ item.applyTip }}
                </p>
              </div>
            </template>
            <div class="top-r">
              <div class="tips">
                <img
                  v-if="!isCoupon"
                  width="16"
                  height="16"
                  :src="item.icon"
                />
                <span
                  class="text"
                  v-html="item.tip"
                >
                </span>
              </div>
              <CountDown
                v-if="item.endTimeStamp"
                class="end-in"
                :prefix="item.endTip"
                :timeStamp="item.endTimeStamp"
                :styleConfig="countDownStyle"
              />
              <p 
                class="full-tip ellipsis"
                v-html="item.fullTip"
              >
              </p>
            </div>
          </div>
          <ProgressBar
            v-if="curThresholds.length"
            class="progress"
            :thresholds="curThresholds"
            transparent
            :style-config="progressStyle"
          />
        </div>
        <div class="right">
          <span 
            class="action-btn ellipsis" 
            @click="handleClick"
          >
            {{ item.buttonTip }}
          </span>
          <div 
            v-if="item.stackableTip"
            class="stackable"
          >
            <img
              width="12"
              height="12"
              :src="item.stackableIcon"
            />
            <span class="stackable-tip ellipsis">
              {{ item.stackableTip }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div 
      v-if="goods.length"
      class="deals-goods"
    >
      <div class="expand-bar">
        <span class="bar-text ellipsis">{{ item.productHeadTitle }}</span>
        <span 
          class="bar-btn" 
          @click="toExpand"
        >
          <span class="ellipsis">{{ isExpand ? item.less : item.more }}</span>
          <Icon 
            is-responsive-name
            :name="isExpand ? 'sui_icon_more_up_12px_2' : 'sui_icon_more_down_12px_2'" 
            size="12px" 
          />
        </span>
      </div>
      <div 
        v-show="isExpand"
        class="sroll-row"
      >
        <div
          v-for="(x, index) in goods"
          :key="index"
          class="goods"
          @click="handleCheck(x)"
        >
          <img 
            :src="cutImg(x.goodsImg)" 
            width="100%"
            height="100%"
          />
          <span
            class="goods-tips ellipsis"
            v-html="replaceText(x.unitPrice)"
          >
          </span>
          <SCheckbox
            class="checkbox"
            :model-value="isCheck(x)"
            :label="x.cartId"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SCheckbox } from '@shein-aidc/sui-checkbox/mobile'
import { defineComponent, computed } from 'vue'
import CountDown from './CountDown.vue'
import ProgressBar from 'public/src/pages/cart_v2/components/common/ProgressBar.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import CornerTag from './CornerTag.vue'
import { web_modify_check_click, web_modify_check_response } from 'public/src/pages/cart_v2/utils/metricTagsConfig.js'
import { SAdapterText } from '@shein-aidc/sui-adapter-text/mobile'
import { modifyCartCheckStatus } from 'public/src/pages/product_app/store/modules/cart_v2/api.js'
import { useCutImg } from 'public/src/pages/cart_v2/hooks/useCutImg.js'
import { Icon } from '@shein-aidc/icon-vue3'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'

export default defineComponent({
  name: 'ItemMoreDeals',
  setup() {
    const { cutImg } = useCutImg()
    const { state, commit, dispatch } = useStore()

    return {
      cutImg,
      promotionState: computed(() => state.promotionState),
      updatePromotionState: (payload) => commit('updatePromotionState', payload),
      fetchCartIndex: () => dispatch('fetchCartIndex'),
    }
  },
  components: { 
    CountDown, 
    ProgressBar,
    CornerTag,
    SAdapterText,
    SCheckbox,
    Icon,
  },
  inject: ['openAppendageDrawer', 'openAddOn'],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    expandPid: {
      type: String,
      default: '',
    },
  },
  computed: {
    goods() {
      return this.item?.products || []
    },
    curThresholds() {
      const reverseArr = [...(this.item?.thresholds || [])].reverse()
      const curIndex = reverseArr?.findIndex(v => v.progressPercent <= 1 && v.progressPercent > 0)
      const curItem = reverseArr[curIndex] || reverseArr[0]
      return curItem ? [curItem] : []
    },
    isCoupon() {
      return this.item?.type === 'coupon'
    },
    isFreeShipping() {
      return this.item?.type === 'freeShipping'
    },
    styleInfo() {
      return {
        '--box-bg': this.isFreeShipping ? '#F5FCFB' : '#FFF6F3',
        '--box-border': this.isFreeShipping ? '#BFDBCF' : '#FFE2CF',
      }
    },
    isExpand() {
      return [this.item.promotionId, this.item.couponCode].includes(this.expandPid)
    },
    countDownStyle() {
      if (this.isFreeShipping) {
        return {
          '--font-color': '#198055',
          '--num-bg-color': 'rgba(25, 128, 85, 0.08)',
        }
      }
      return {
        '--font-color': '#FA6338',
      }
    },
    progressStyle() {
      if (this.isFreeShipping) {
        return {
          progressHeight: 6,
          bottomBg: 'rgba(25, 128, 85, 0.15)',
          barBg: 'linear-gradient(to right, #198055, #6ABD8C 38%)',
        }
      }
      return {}
    }
  },
  methods: {
    handleClick() {
      this.updatePromotionState({ mixSelectedCartIds: [] })

      // 附属品
      const { typeId, promotionId, isContinueAddItem } = this.item
      if ([4, 28, 2, 22, 13].includes(+typeId) && isContinueAddItem == false) {
        this.openAppendageDrawer?.(promotionId)
        return
      }

      // 打开凑单
      this.openAddOn(this.item)
    },
    replaceText(unitPrice) {
      const { amountWithSymbol, priceShowStyle } = unitPrice || {}
      return amountWithSymbol?.replace(priceShowStyle, `<em>${priceShowStyle}</em>`) || ''
    },
    isCheck(product) {
      return product.isChecked == '1'
    },
    handleCheck(product) {
      const cartId = product?.cartId
      const toChecked = !this.isCheck(product)

      web_modify_check_click(toChecked ? 1 : 0, 'item')
      const reqParam = {
        operation_type: toChecked ? 1 : 2,
        cart_id_list: [cartId],
        append_id_list: [],
      }

      if (toChecked) {
        const oldList = this.promotionState?.mixSelectedCartIds || []
        oldList.push(cartId)
        this.updatePromotionState({ mixSelectedCartIds: Array.from(new Set(oldList)) })
      }

      modifyCartCheckStatus(reqParam).then(async (res) => {
        await this.fetchCartIndex()
        web_modify_check_response(toChecked ? 1 : 0, 'item', res?.code == 0 ? 1 : 0)
      })

      const pid = this.item.promotionId || this.item.couponCode
      daEventCenter.triggerNotice({
        daId: '1-8-3-7',
        extraData: {
          goods_sn: product?.goodsSn,
          is_select: toChecked ? 0 : 1,
          scene: 'cartpromos',
          select_type: 1,
          position: 'page',
          unlock_deals: pid,
        }
      })
      window.sessionStorage.setItem('checked_goods_promotion_id', pid)
    },
    toExpand() {
      this.$emit('handleExpand', this.item.promotionId || this.item.couponCode)
    }
  },
})
</script>

<style lang="less" scoped>
@BorderWidth: 1/37.5rem;
@CircleLeft: 84/37.5rem;
@CircleRadius: 4/37.5rem;
@CircleBgColor: #FFF;
.item-more-deals {
  position: relative;
}
.deals-content {
  width: 100%;
  padding: 8/37.5rem 12/37.5rem;
  position: relative;
  border: @BorderWidth solid var(--box-border);
  border-radius: 4/37.5rem;
  background: var(--box-bg);
  z-index: 1;
  &.is-coupon {
    &::before {
      content: '';
      position: absolute;
      width: @CircleRadius * 2;
      height: @CircleRadius;
      background-color: @CircleBgColor;
      border-radius: 0 0 @CircleRadius @CircleRadius;
      border: @BorderWidth solid var(--box-border);
      border-top: none;
      left: @CircleLeft - @CircleRadius;
      top: -@BorderWidth;
    }
    &::after {
      content: '';
      position: absolute;
      width: @CircleRadius * 2;
      height: @CircleRadius;
      background-color: @CircleBgColor;
      border-radius: @CircleRadius @CircleRadius  0 0;
      border: @BorderWidth solid var(--box-border);
      border-bottom: none;
      left: @CircleLeft - @CircleRadius;
      bottom: -@BorderWidth;
    }
  }
  .wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    min-height: 58/37.5rem;
    .left {
      flex: 1;
      overflow: hidden;
      .top { /* stylelint-disable-line selector-max-specificity */
        display: flex;
        align-items: stretch;
        margin-bottom: 3/37.5rem;
        .top-l { /* stylelint-disable-line selector-max-specificity */
          display: flex;
          flex-shrink: 0;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          width: 72.5/37.5rem;
          margin-right: 8/37.5rem;
          padding-right: 8/37.5rem;
          overflow: hidden;
          border-right: 1px dashed #FDC1A5;
          .coupon-face { /* stylelint-disable-line selector-max-specificity */
            line-height: 20px;
            font-size: 16px;
            color: #FA6338;
            font-weight: bold;
          }
          .coupon-threshold { /* stylelint-disable-line selector-max-specificity */
            width: 100%;
            color: rgba(250, 99, 56, 0.70);
            text-align: center;
            font-size: 10px;
            line-height: 12px;
          }
        }
        .top-r { /* stylelint-disable-line selector-max-specificity */
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          overflow: hidden;
          min-height: 30/37.5rem;
          .tips { /* stylelint-disable-line selector-max-specificity */
            line-height: 15px;
            font-size: 12px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            position: relative;
            .text { /* stylelint-disable-line selector-max-specificity */
              font-size: 12px;
              padding-left: 2/37.5rem;
              vertical-align: middle;
            }
          }
          .end-in { /* stylelint-disable-line selector-max-specificity */
            margin-top: 2/37.5rem;
          }
          .full-tip { /* stylelint-disable-line selector-max-specificity */
            color: @sui_color_gray_light1;
            font-size: 10px;
            line-height: 12px;
            margin-top: 2/37.5rem;
          }
        }
      }
    }
    .right {
      flex-shrink: 0;
      width: 65/37.5rem;
      padding-left: 8/37.5rem;
      overflow: hidden;
      text-align: right;
      .action-btn { /* stylelint-disable-line selector-max-specificity */
        display: inline-block;
        text-align: center;
        min-width: 46/37.5rem;
        max-width: 100%;
        padding: 0 3/37.5rem;
        background-color: #000;
        margin: 2/37.5rem 0;
        line-height: 22/37.5rem;
        color: #fff;
        border-radius: 2/37.5rem;
        vertical-align: middle;
      }
      .stackable { /* stylelint-disable-line selector-max-specificity */
        display: flex;
        align-items: center;
        .stackable-tip { /* stylelint-disable-line selector-max-specificity */
          font-size: 9px;
          font-weight: bold;
          color: #FA6338;
          padding: 1/37.5rem 1/37.5rem 0;
        }
      }
    }
  }


}
.deals-goods {
  position: relative;
  padding: 12/37.5rem 12/37.5rem 12/37.5rem;
  border-radius: 0px 0px 4/37.5rem 4/37.5rem;
  border: 0.5px solid #F4DFB3;
  background-color: #FFF9E4;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(-4/37.5rem);
  &::before {
    content: ' ';
    position: absolute;
    background-color: #FFF9E4;
    height: 6/37.5rem;
    left: 0;
    right: 0;
    top: -6/37.5rem;
  }
  .expand-bar {
    width: 100%;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .bar-text {
      color: #0C0C0C;
      font-size: 12px;
      padding-right: 8/37.5rem;
    }
    .bar-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 64/37.5rem;
      color: @sui_color_gray_dark2;
    }
  }
  .sroll-row {
    width: 100%;
    padding-top: 8/37.5rem;
    overflow-x: auto;
    display: flex;
    scrollbar-width: none; // 火狐
    -ms-overflow-style: none; // ie
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .goods { /* stylelint-disable-line selector-max-specificity */
    position: relative;
    width: 60/37.5rem;
    height: 60/37.5rem;
    border: 0.5px solid #F4DFB3;
    border-radius: 3/37.5rem;
    overflow: hidden;
    flex-shrink: 0;
    &:not(:last-of-type) {
      margin-right: 7/37.5rem;
    }
    .goods-tips { /* stylelint-disable-line selector-max-specificity */
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50%;
      color: #fff;
      font-size: 10px;
      font-weight: 600;
      line-height: 14/37.5rem;
      text-align: center;
      padding: 14/37.5rem 2/37.5rem 2/37.5rem;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00)0%, rgba(0, 0, 0, 0.80)100%);
      :deep(em) {
        font-size: 12px;
      }
    }
    .checkbox {
      position: absolute;
      left: 2px;
      top: 2px;
      display: inline;
      opacity: 0.9;
      :deep(.sui-checkbox__label-select) { /* stylelint-disable-line selector-max-specificity */
        width: 18/37.5rem;
        height: 18/37.5rem;
      }
    }
  }
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
